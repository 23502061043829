@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-interaction-bar] {
  display: flex;
  height: 50px;
  
  @include media("<=phone") {
    height: 90px;
  }

  [data-relevante] {
    > span {
      margin-right: 0.8em;
    }

    @include media("<=phone") {
      margin-left: 6px !important;
     }
  }

  > section {
    width: 1246px;
    margin-left: $default-section-padding-side;
    margin-right: $default-section-padding-side;
    display: flex;
    justify-content: space-between;

    @include media("<=phone") {
      display: flex;
      justify-content: center;
      align-items: center;
     }

    @include media("<=desktop") {
      width: 100%;
      margin: 0px;
      padding-left: 35px;
      padding-right: 35px;
    }

    [data-compartilha] {
      margin-left: 15px;
    }


    [data-clickable] {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 50px;

      @include media("<=phone") {
        display: flex;
        margin-right: 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
       }

      &:not(:last-child) {
        // margin-right: 61px;
      }

      

      img {
        cursor: pointer;
        width: 24px;
        margin-right: 12px;

        @include media("<=phone") {
          margin-bottom: 10px;
          margin-right: 0px;
        }

        &:not(:first-child) {
          margin-left: 14px;
        }
      }

      @include media("<=desktop") {
        .like-container,
        .like-container img {
          margin-right: 0px;
        }
      }

    }

    > div {
      display: flex;
      white-space: nowrap;
      color: $color-green-blue;
      font-size: $font-med;
      font-family: $font-family-opensans;
      align-items: center;

      @include media("<=phone") { 
        justify-content: space-between;
        width: 100%;
      }

      @include media("<=phone") {
        height: 90px;
      }

      img {
        cursor: pointer;
      }
    }
  }
}
