@import "../../../../ds/media";

[data-knolegdes-areas] {
  margin-bottom: 70px;

  & > div {
    margin-bottom: 70px;

    @include media("<=desktop") {
      margin-bottom: 40px;
    }
  }

  @include media("<=desktop") {
    margin-bottom: 40px;
  }

  @include media("<=phone") {
    .card-section {
      &__title-bar {
        padding: 0;
        white-space: nowrap;
      }
      &__link {
        white-space: nowrap;
      }
    }

    .container {
      padding: 0;
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
}
