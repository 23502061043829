@import "../../../../../ds/variables";
@import "../../../../../ds/media";


.espaco {
  margin-bottom: 80px;
}

[data-audio-preview-desktop] {
  display: flex;
  background-color: #000000;

  >a {
    cursor: pointer;
  }

  @include media("<=phone") {
    flex-direction: column-reverse;
  }


  color: $color-white;

  @include media("<=desktop") {
    padding: 20px 25px;
    background-image: none !important;
    background-size: none !important;
    width: 100%;
    padding: 0px;
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .colorWhite {
    color: $color-white !important;

    >p {
      color: $color-white !important;
    }
  }

  >div {
    max-width: 1246px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;

    >a {
      color: red;
    }

    @include media(">=desktop") {
      min-height: 591px;
    }

    @include media("<=desktop") {
      max-width: 100%;
      width: 100%;
      margin: 0px;
      flex-wrap: wrap;
    }

    [data-audio-info-desktop] {
      width: 500px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 63px;

      @include media("<=phone") {
        border-radius: 10px 10px 0 0;
        background-color: $color-white;
        margin-top: -15px;
      }

      h1 {
        align-items: center;
        color: $color-white;

        >div {
          display: flex;
          align-items: flex;
          position: absolute;
          margin-left: -60px;
        }
      }


      svg {
        fill: $color-default;
        margin-right: 10px;
        cursor: pointer;
      }

      @include media("<=desktop") {
        margin-right: 0px;
        width: 100%;
      }

      h1 {
        font-size: 30px;
        font-weight: $weight-bold;
        width: 100%;
        margin-bottom: 18px;
        margin-top: 18px;

        @include media("<=desktop") {
          font-size: 16px;
          padding: 0px 25px 0px 25px;
        }



        >span {
          margin-right: 20px;
        }
      }

      [data-audio-info-time] {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 10px;

        [data-audio-info-icon] {
          padding: 1px 3px 1px 3px;
          border: 3px solid #107B95;
          border-radius: 8px;
          color: #107B95;
          font-weight: 900;
          font-size: 9px;
        }

        [data-audio-info-icon-img] {
          margin: 0px;
          width: 20px;
          height: 20px;
        }

        [data-audio-info-type] {
          margin-left: 5px;
          font-family: $font-family-monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $color-white;
        }

        [data-audio-info-icon-date] {
          margin: 0px 0px 0px 10px;
          width: 20px;
          height: 20px;
        }
      }

      [data-audio-info-palavra-chave] {
        margin-top: 25px;
        color: $color-white;

        [data-audio-info-palavra-chave-title] {
          font-family: $font-family-monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $color-white;
        }

        [data-audio-info-tags] {
          margin-top: 12px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .tags {
            >span {
              background-color: #107B95 !important;
              color: $color-white !important;
            }
          }
        }

        [data-audio-info-ver-mais] {
          cursor: pointer;

          [data-audio-info-icone-ver-mais] {
            margin: 0px;
            width: 24px;
            height: 24px;
          }

          [data-audio-info-icone-ver-mais-label] {
            font-family: $font-family-monserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #0A589B;
            margin-left: 12px;
          }
        }
      }

      [data-audio-recommended-desktop] {
        width: 100%;
        margin-bottom: 24px;
        line-height: 1.3;

        @include media("<=desktop") {
          padding: 0px 0px 0px 25px;
          margin-bottom: 0px;
        }

        a {
          font-family: $font-family-monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #111111;
        }
      }

      [data-audio-content-desktop] {
        width: 100%;
        height: auto;
        margin-top: 18px;
        margin-right: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-color: #9b9b9b #f4f4f4;
        scrollbar-width: thin;

        * {
          line-height: 1.5;
        }



        [data-audio-content-desktop-recomendado] {
          font-family: $font-family-monserrat;
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          color: $color-white;
        }

        p {
          margin-top: 16px;
          font-family: $font-family-monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $color-white;
        }

        @include media("<=desktop") {
          height: auto;
          width: 100%;
          padding: 0px 25px;
        }

        img {
          @include media("<=desktop") {
            width: 100%;
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
          background: #f4f4f4;
          border: solid 2px #e4e4e4;
          border-radius: 0px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 0px;
          box-shadow: inset 0 0 6px #9b9b9b;
          -webkit-box-shadow: inset 0 0 6px #f4f4f4;
        }

        &::-webkit-scrollbar-thumb {
          margin: 2px;
          border-radius: 0px;
          position: absolute;
          background: #107b95;
          border: 2px solid #e4e4e4;
        }
      }

      .button {
        margin-top: 2rem;
        background-color: $color-green-blue;

        @include media("<=desktop") {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 30px;
        }
      }
    }

  }

  [data-audio-play] {
    position: relative;
    width: 239%;
    z-index: 99;
    margin-bottom: 22px;
    margin-top: 22px;
  }
}

[data-audio-img-preview-desktop] {

  background-repeat: no-repeat;
  background-size: cover;

  @include media(">phone") {
    -webkit-mask-image: linear-gradient(to right, transparent 2%, black 50%);
  }

  @include media("<=phone") {
    height: 200px;
  }
}

[data-audio-preview] {
  padding: 48px 0 0;
  background-size: cover;
  background-position: center top;
  color: $color-white;
  height: 591px;
  position: relative;
  
  @include media("<=desktop") {
    height: auto;
    padding: 20px 25px;
    background-image: none !important;
    background-size: none !important;
    width: 100%;
    padding: 0px;
  }

  > div {
    max-width: 1246px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  justify-content: space-around;


    height: 100%;

  

    @include media("<=desktop") {
      max-width: 100%;
      width: 100%;
      margin: 0px;
    }

    [data-preview-info] {
      width: 65%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include media("<=desktop") {
        margin-right: 0px;
        width: 100%;
        background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, .92) 0%,
          rgba(0, 0, 0, .96) 10%,
          rgba(0, 0, 0, .96) 28%,
          rgba(0, 0, 0, .96) 80%
        );
      }

      h1 {
        color: $color-white;
        font-size: 30px;
        font-weight: $weight-bold;
        text-transform: uppercase;
        width: 100%;
        margin-bottom: 18px;

        @include media("<=desktop") {
          font-size: 14px;
          padding: 25px 25px 0px 25px;
        }

        > span {
          margin-right: 20px;
        }
      }

      [data-tags] {
        margin-bottom: 8px;
      }

      [data-metadata] {
        display: flex;
        width: 80%;
        margin-bottom: 18px;

        @include media("<=desktop") {
          padding: 0px 25px;
        }

        [data-time-view] {
          display: flex;
          align-items: center;
          margin-right: 16px;
        }

        [data-date-view] {
          display: flex;
          align-items: center;
          font-family: $font-family-opensans;

          > div {
            padding-bottom: 0px;
          }
        }
      }

      [data-tags] {
        @include media("<=desktop") {
          padding: 0px 25px;
        }
      }

      // [data-image-preview-mobile] {
      //   position: relative;
      //   padding: 0px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   @include media("<=desktop") {
      //     height: 273px;
      //     width: 100%;
      //   }

      //   [data-image-gradient] {
      //     position: absolute;
      //     height: 100%;
      //     width: 100%;
      //     background: rgb(0, 0, 0);
      //     background: linear-gradient(
      //       0deg,
      //       rgba(0, 0, 0, 0.91) 0%,
      //       rgba(0, 0, 0, 0.014) 50%,
      //       rgba(0, 0, 0, 0.65) 80%
      //     );
      //   }

      //   [data-back-image] {
      //     height: 100%;
      //     width: 100%;
      //     background-size: cover;
      //     background-position: center top;
      //   }

      //   [data-play-button-mobile] {
      //     position: absolute;
      //     z-index: 120;
      //     width: 50px;

      //     &>img {
      //       width: 55px;
      //     }
      //   }
      // }

      [data-recommended] {
        width: 80%;
        margin-bottom: 37px;
        line-height: 1.3;
        @include media("<=desktop") {
          padding: 25px 25px 0px 25px;
        }
      }

      [data-content] {
        width: 80%;
        //height: 240px;
        margin-right: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-color: #9b9b9b #f4f4f4;
        scrollbar-width: thin;

        * {
          line-height: 1.5;
        }

        @include media("<=desktop") {
          height: auto;
          width: 100%;
          padding: 0px 25px;
        }

        img {
          @include media("<=desktop") {
            width: 100%;
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
          background: #f4f4f4;
          border: solid 2px #e4e4e4;
          border-radius: 0px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 0px;
          box-shadow: inset 0 0 6px #9b9b9b;
          -webkit-box-shadow: inset 0 0 6px #f4f4f4;
        }

        &::-webkit-scrollbar-thumb {
          margin: 2px;
          border-radius: 0px;
          position: absolute;
          background: #107b95;
          border: 2px solid #e4e4e4;
        }
      }

      .button {
        margin-top: 2rem;
        background-color: $color-green-blue;
        @include media("<=desktop") {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 30px;
        }
      }
    }

    [data-preview-play] {
      display: flex;
      align-items: center;
      margin-top: 167px;
    }
  }
  
}

@include media("<=phone") {
  .deslogado {
    bottom: 55px !important;
  }
}
@include media("<=tablet") {
  .deslogado {
    bottom: 55px !important;
  }
}
