@import "../../../ds/variables";
@import "../../../ds/media";

[data-modal-optin-pronto] {
  width: 664px;
  padding: 0px;
  padding-bottom: 60px;

  @include media("<=desktop") {
    width: 100%;
    padding: 0 26px;
  }

  h1 {
    font-size: 1.2rem;
    color: $color-primary;
    justify-content: center;
    display: flex;
    margin: 60px 40px 11px 40px;
    font-weight: bold;

    @include media("<=desktop") {
      text-align: center;
      margin: 30% 0px 11px 0px;
    }
  }

  [data-description] {
    font-size: 1rem;
    text-align: center;
    color: $color-default;
    justify-content: center;
    display: flex;
    margin: 0px 40px 0.5rem 40px;
    padding: 0 60px;

    @include media("<=desktop") {
      padding: 0;
      margin: 1rem 0px 1rem 0px;
    }
  }

  [data-terms] {
    font-size: 10px;
    color: $color-default;
    text-align: center;
    max-width: 490px;
    margin: 0 auto;
    margin-top: 15px;

    @include media("<=desktop") {
      width: 100%;
    }
  }

  [data-buttons] {
    margin-top: 23px;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;

    @include media("<=desktop") {
      flex-direction: column;
      justify-content: center;
    }

    & > button {
      width: 161px;
      padding: 0.5rem 0px;

      @include media("<=tablet") {
        width: 30%;
      }

      @include media("<=phone") {
        width: 100%;
      }
    }

    & > a{
      margin-top: 1rem;
      color: $color-primary;
      text-decoration: underline;
      font-size: $font-sm;
      cursor: pointer;
    }
  }
}
