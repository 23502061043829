@import "../../../../../ds/variables";
@import "../../../../../ds/media";
@import "../../../../../ds/global";

[data-video-wath-container] {
  display: flex;
  flex-direction: row;

  @include media("<=tablet") {
    flex-direction: column;
  }

  .fixar {
    @include media("<=tablet") {
      position: fixed;
      top: 49px;
      z-index: 999;
      margin-top: 0px !important;
    }
  }

  .fixar-chat {
    @include media("<=tablet") {
      margin-top: 360px !important;
    }
  }

  [data-video-wath-chat] {
    width: 27%;
    @include media("<=tablet") {
      width: 100%;
      height: 354px;
    }
  }

  [data-video-container] {
    background: rgb(0, 0, 0);
    width: 100%;

    .video-live {
      width: 73%;
    }

    [data-video-container-inner] {
      position: relative;
      width: 100%;
      margin: 0px;

      @include media("<=phone") {
        height: 252px;
      }

      @include media("<=tablet") {
        height: 320px;
        margin: 0 auto;
      }

      @include media(">tablet") {
        height: 700px;
        margin: 0 auto;
      }

      [data-video-header] {
        display: flex;
        background: rgb(0, 0, 0);
        width: 100%;
        padding: 10px;

        [data-oe-back-button] {
          position: absolute;
        }

        @include media("<=desktop") {
          padding: 0px;

          [data-oe-back-button] {
            display: none !important;
          }
        }
      }

      .video-content-mobile {
        position: fixed;
      }

      [data-video-content] {
        width: 100%;

        .player-video {
          iframe {
            width: 100%;
            height: 620px;

            @include media("<=phone") {
              height: 200px;
            }

            @include media("<=tablet") {
              margin-left: 2.5%;
              width: 95%;
              height: 300px;
            }
          }
        }
      }
    }

    [data-tools] {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;

      &[data-tools*="navigation"] {
        padding-top: 4rem;

        [data-vertical-stepper] {
          padding-top: 3rem;
          margin-left: -70px;

          [data-progress-bar] {
            width: 4px;
            background-color: #abcad3;
            position: relative;

            [data-progress] {
              position: absolute;
              height: 100px;
              top: 0;
              width: 100%;
              background-color: #107b95;
              padding-bottom: 22px;
              box-sizing: content-box;
              transition: height 0.4s ease-in-out;
            }
          }
        }

        [data-step] {
          white-space: nowrap;
          display: flex;
          font-size: 12px;
          margin-left: -3px;
          margin-bottom: 3rem;
          cursor: pointer;

          > div {
            &:last-of-type {
              position: relative;
              bottom: 2px;
            }
          }

          &:first-of-type {
            > div:last-of-type {
              position: relative;
              top: 1px;
            }
          }

          &:last-of-type {
            position: relative;
            bottom: -6px;

            > div:last-of-type {
              position: relative;
              top: -3px;
            }
          }

          &[data-step*="past"] {
            [data-bullet] {
              background-color: #107b95;
            }
          }

          &[data-step*="current"] {
            > div {
              &:last-of-type {
                font-weight: bold;
              }
            }

            [data-bullet] {
              min-width: 16px;
              height: 16px;
              border-radius: 8px;
              border: #107b95 solid 1px;
              background-color: #fff;
              margin-left: -3px;
              position: relative;

              &::after {
                content: "";
                min-width: 10px;
                height: 10px;
                background-color: #107b95;
                border-radius: 5px;
                position: absolute;
                left: 2px;
                top: 2px;
              }
            }
          }
        }

        [data-bullet] {
          min-width: 10px;
          height: 10px;
          background-color: #abcad3;
          border-radius: 5px;
          margin-right: 1rem;
        }
      }
    }

    [data-scrollable] {
      height: 600px;
      overflow: auto;
      position: relative;
      padding-right: 1rem;
      margin-bottom: 2rem;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-green-blue;
        border: 3px solid #eaeaea;
      }
    }

    [data-text-container] {
      padding: 0 4rem;
      display: flex;
      flex-direction: column;
      flex-grow: 2;
    }
  }
}
