@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@import "./mixins";
@import "./variables";
@import "./media";

html,
html * {
  // reduzimos o tamanho da fonte padrão para utilizar técnicas avançadas de responsividade
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-family: $font-family-monserrat;
  line-height: 1.3;
  color: $color-default;

  @include media("<=desktop") {
    margin-top: 48px;
  }
}

/**
 * Design system tipografia
 */
h2 {
  color: $color-white;

  font-weight: $weight-bold;
  text-transform: uppercase;

  @include media(">=desktop") {
    font-size: 2.1875em;
  }
}

h3 {
  font-size: $font-med;
}

h3 {
  font-size: $font-med;
  font-weight: bold;
}

h4 {
  font-size: $font-med2;
}

p {
  font-family: $font-family-opensans;
  font-size: $font-normal;
}

a {
  text-decoration: none;
}

.flex {
  display: flex;

  &-jc-sb {
    justify-content: space-between;
  }

  &-jc-c {
    justify-content: center;
    text-align: center;
  }

  &-jc-r {
    justify-content: flex-end;
  }

  &-ac-c {
    align-content: center;
  }

  &-ai-c {
    align-items: center;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-column {
    flex-direction: column;
  }

  &-ast {
    align-items: start;
  }
}

.align {
  &-center {
    text-align: center;
  }
}

.pd-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.link {
  transition: color 200ms ease-in-out;

  &-primary {
    color: $color-primary;
    font-size: $font-sm;
    font-weight: $weight-bold;
    text-decoration: underline;

    &:hover {
      color: $color-light-blue;
    }

    &:focus {
      color: $color-light-blue;
    }
  }

  &-secondary {
    color: $color-white;

    &:hover {
      color: $color-light-blue;
    }

    &:focus {
      color: $color-light-blue;
    }
  }

  &:focus {
    color: $color-light-blue;
  }
}

[data-link-primary] {
  transition: color 200ms ease-in-out;

  color: $color-primary;
  font-size: $font-sm;
  font-weight: $weight-bold;
  text-decoration: underline;

  &:hover {
    color: $color-light-blue;
  }

  &:focus {
    color: $color-light-blue;
  }
}

[data-link-primary] {
  transition: color 200ms ease-in-out;

  color: $color-primary;
  font-size: $font-sm;
  font-weight: $weight-bold;
  text-decoration: underline;

  &:hover {
    color: $color-light-blue;
  }

  &:focus {
    color: $color-light-blue;
  }
}

.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none !important;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none !important;
  }
}

.shadow {
  &__bottom {
    -webkit-box-shadow: 0px 3px 10px 0px rgba(107, 106, 107, 0.5);
    -moz-box-shadow: 0px 3px 10px 0px rgba(107, 106, 107, 0.5);
    box-shadow: 0px 3px 10px 0px rgba(107, 106, 107, 0.5);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1100;
  background: rgba(0, 0, 0, 0.7);
}

.alert {
  margin: 0px;
  &-error {
    color: red;
  }
}

$widths: (
  50: 50%,
  65: 65%,
  100: 100%,
);

[data-width] {
  @each $name, $value in $widths {
    &[data-width*="#{$name}"] {
      width: $value;
    }
  }
}

$justifyContent: (
  sb: space-between,
);

[data-justify-content] {
  @each $name, $value in $justifyContent {
    &[data-justify-content*="#{$name}"] {
      justify-content: $value;
    }
  }
}

$alignItems: (
  center: center,
);

[data-align-items] {
  @each $name, $value in $alignItems {
    &[data-align-items*="#{$name}"] {
      align-items: $value;
    }
  }
}

[data-flex-wrap] {
  flex-wrap: wrap;
}

[data-col-3] {
  width: 25%;
}

$lineHeight: (
  1: 1,
  1-2: 1.2,
  1-3: 1.3,
  1-4: 1.4,
);

[data-line-height] {
  @each $name, $value in $lineHeight {
    &[data-line-height*="#{$name}"] {
      line-height: $value;
    }
  }
}

[data-color] {
  @each $name, $value in $colors {
    &[data-color*="#{$name}"] {
      color: $value;
    }
  }
}

[data-error] {
  padding-top: 6px;
  color: red;
  font-size: 14px;
  display: flex;
  width: 100%;
  text-align: left;
}

[data-flex] {
  display: flex;
}

[data-flex-direction] {
  &[data-flex-direction*="column"] {
    flex-direction: column;
  }
}

// .swiper-button-hidden {
//   & .swiper-button-next {
//     display: none;
//   }
//   & .swiper-button-prev {
//     display: none;
//   }
// }
