@import "../../ds/variables";
@import "../../ds/media";

[data-tema-page] {
  [data-header-section] {
    display: flex;

    [data-header-section-sub-title] {
      display: flex;
      
      @include media(">=desktop") {
        margin-bottom: 2rem;
        h3 {
          font-family: $font-family-monserrat;
          font-style: $weight-normal;
          font-weight: $weight-semibold;
          font-size: $font-med1;
          line-height: 27px;
          color: $color-white;
        }
      }
    }

    [data-header-info] {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      align-self: flex-start;
      margin-top: 5px;

      @include media("<desktop") {
        width: 0.5rem;
        margin-top: 0px;
      }
    }
  }

  [data-container] {
    padding: 25px;

    @include media(">=desktop") {
      padding: 40px calc((100% - 1246px) / 2);
    }

  }
}
