@import "../../../ds/variables";

.selectLabel {
  visibility: hidden;
  display: none;
}

.selectField {
  display: block;
  width: 100%;
  padding: 0.375rem 1.25rem 0.375rem 0.1rem;
  font-size: 1rem;
  font: normal normal normal 12px/14px Open Sans;
  letter-spacing: 0;
  line-height: 1.5;
  background-image: url("data:image/svg+xml,<svg stroke='currentColor' fill='#5c6268' strokeWidth='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path d='M128 192l128 128 128-128z'></path></svg>");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: right 0 center;
  background-size: 18px 18px;
  border: 0;
  border-bottom: 1px solid #707070;
  border-radius: 0;
  color: #707070;
  appearance: none;

  &:focus {
    box-shadow: none;
    outline: 0;
    border-bottom-color: orange;
  }

  &.colorWhite {
    background-image: url("data:image/svg+xml,<svg stroke='currentColor' fill='white' strokeWidth='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path d='M128 192l128 128 128-128z'></path></svg>") !important;
    background-color: transparent;
    color: $color-white;
    border-bottom: 1px solid $color-white;

    & > option {
      background-color: $color-default !important;
    }
  }

  &.colorDark {
    background-image: url("data:image/svg+xml,<svg stroke='currentColor' fill='black' strokeWidth='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path d='M128 192l128 128 128-128z'></path></svg>") !important;
    background-color: transparent;
    color: $color-default;
    border-bottom: 1px solid $color-default;
  }

  font-family: $font-family-monserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 0.865rem;
  line-height: 17px;
  color: #707070;
}
