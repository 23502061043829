@import "../../../../ds/variables";
@import "../../../../ds/media";



[data-profession-areas-inner] {
  align-items: center;
  align-self: center;
  gap: 24px;
  justify-items: center;
  display: grid;
  grid-auto-flow: dense; /* [2] */
  grid-template-columns: repeat(6, 1fr); /* [1] */

  @include media("<=tablet") {
    gap: 0px;
    grid-template-columns: repeat(auto-fit, 10rem); /* [1] */
  }

  @include media("<=phone") {
    gap: 0px;
    grid-template-columns: repeat(auto-fit, 10rem); /* [1] */
  }
}

ol, ul {
  padding-left: 0rem !important;

  @include media("<=phone") { 
    justify-content: center;
  }

}

[data-knowlegde-areas-inner] > * {
  display: flex;
  flex-direction: column;
}

