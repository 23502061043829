[data-loader] {
  align-items: center;
  background-color: rgba(10, 88, 155, 0.99);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999 !important;

  > img {
    position: relative;
  }

  p {
    color: white;
    margin-top: 2em;
    margin-bottom: 50px;
  }

  &[data-show*="false"] {
    // animation: fadeOut 100ms ease 5000ms;
    // animation-fill-mode: both;
    opacity: 0;
    transition: opacity 300ms linear;
  }
}
