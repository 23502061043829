@media (min-width: 1246px) {
  .hero {
    &__content {
      width: 47%;
    }
  }

  .container {
    width: 1260px;
    margin: 0 auto;
  }

  .destak,
  .destak-carousel {
    width: 400px;
  }

  .destak {
    padding-left: 0px;
    padding-top: 0px;
    margin-bottom: 0px;

    &-carousel {
      width: 400px;
    }

    .arrow {
      &.forward {
        left: 574px;
      }
    }
  }

  .home-destak {
    &__title {
      margin-bottom: 47px;
    }

    &__subtitle {
      margin-bottom: 36px;
    }
  }

  .hero {
    padding: 0px;

    > img {
      display: block;
      width: 1345px;
      margin: 0 auto;
    }
  }

  header {
    > nav {
      > div {
        // padding-left: calc((100% - 1246px) / 2);
        // padding-right: calc((100% - 1246px) / 2);
      }
    }
  }

  .header {
    background-color: $color-white;
    padding-left: 0px;
    padding-right: 0px;
    &__nav-top {
      // padding-left: calc((100% - 1246px) / 2);
      // padding-right: calc((100% - 1246px) / 2);
    }
  }

  .footer {
    img {
      width: 292px;
      padding: 0px;
      margin: 60px 60px 0px 0px;
    }
  }

  .quiz-week {
    height: 300px;
    padding: 24px 24px 0px 24px;

    &__content {
      .button {
        margin-right: 35px;
      }
    }

    &__image {
      width: auto;
      margin: 42px 0px 0px 0px;
    }

    &__footer {
      padding: 12px 0px;
    }
  }

  .home-favorite {
    &__title {
      padding-left: 0px;
      padding-right: 0px;
    }

    & > button {
      min-width: 206px;
      width: 206px !important;
    }
  }

  .user {
    &__steps {
      width: 513px;
      margin-right: 27px;
      padding-top: 0px;
      padding-left: 0px;

      &__line {
        width: 513px;
      }

      .step {
        &.active:before {
          width: 250px;
        }
      }
    }

    &__title {
      margin-bottom: 36px;
    }

    &__header {
      margin-bottom: 46px;
      display: flex;
      justify-content: space-between;
    }
  }

  .new-user {
    padding: 40px;
  }

  .form-base {
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 3px 10px 0px rgba(107, 106, 107, 0.5);
  }

  .modal-login {
    width: 530px;
    height: 320px;
    left: 50%;
    top: 50%;
    margin-left: -265px;
    margin-top: -160px;
    border-radius: 8px;
    padding: 13px 26px;
    padding: 0px;

    &__title {
      display: none;
    }

    &__subtitle {
      text-transform: uppercase;
      margin: 33px 0px 30px 0px;
    }

    form {
      margin: 0px 26px;
    }
  }
}

@media (max-width: 1246px) {
  .header {
    // padding-right: 1.5625rem;
    // padding-left: 1.5625rem;
  }
}

// 640px, 1024px, 1400;
$breakpoints-up: (
  "medium": "40em",
  "large": "64em",
  "xlarge": "87.5em",
);

// 639px, 1023px, 1399;
$breakpoints-down: (
  "small": "39.9375em",
  "medium": "63.9375em",
  "large": "87.4375em",
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}
