[data-lembrar] {
  img {
    cursor: pointer;
  }
}
.remember-container {
  margin-right: 0%;
  img {
    height: 19px;
  }
}
