@import "../../ds/variables";
@import "../../ds/media";

[data-banner-cabecalho] {
}

.residencia {
  [data-banner] {
    margin: 0 auto;
  }

  [data-banner][data-banner-cabecalho] {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  [data-banner][data-banner-cabecalho] {
    margin: 40px auto;
    width: 946px !important;
    height: 179px;
    position: relative;

    @include media("<=phone") {
      width: 100%;
      height: 150px;
      justify-content: center;
      border-radius: 12px;
    }
  }
}
