@import "../../ds/variables";
@import "../../ds/media";

[data-card] {
  @include media("<=phone") {
    margin-bottom: 0px !important;
  }
}

[data-card-metodos] {
  display: flex;

  border-radius: 12px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-size: cover;

  width: 100% !important;
  height: 70% !important;

  @include media("<=phone") {
    height: 60% !important;
  }

  @include media(">=tablet") {
    height: 70% !important;
  }

  @include media(">=desktop") {
    width: 291px !important;
    height: 185px !important;
  }

  &[data-card-metodos*="embreve"] {
    h4 {
      color: $color-gray;
    }
  }

  h4 {
    width: 80%;
    font-family: $font-family-monserrat;
    font-size: $font-med1;
    font-weight: 700;
    font-style: normal;
    color: $color-white;
    word-wrap: break-word;
  }
}

[data-card-metodos-description] {
  display: flex;
  width: 100%;

  @include media(">=desktop") {
    max-height: 200px;
    height: 50px;
  }

  margin-top: 20px;
  text-align: start;
  font-family: $font-family-monserrat;
  font-size: $font-normal;
  font-weight: 400;
  font-style: normal;
  color: #707070;

  a {
    word-break: break-word;
    text-overflow: ellipsis;
    line-height: 16px;
    // word-break: break-word;
    // overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    // line-height: 16px;
    /* fallback */
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

[data-card-em-breve] {
  background: #00498d;
  border-radius: 50px;
  width: 79px;
  height: 24px;
  position: absolute;
  float: left;
  left: 12px;
  top: 12px;

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #fdfeff;
  }
}

[data-media-container] {
  // padding: 60px 25px;

  @include media(">=desktop") {
    // width: 1246px;
    // margin: 0 auto;
    // padding-right: 0;
    // padding-left: 0;
  }

  @include media("<=phone") {
    border-radius: 10px 10px 0 0;
    margin-top: -12px;
    background-color: #fdfeff;
  }

  [data-card] {
    position: relative;
    width: 100% !important;
    height: 254px !important;

    @include media("<=phone") {
      height: 320px !important;
    }

    text-align: center;
  }
}
