@import "../../ds/variables";
@import "../../ds/media";

[data-footer-desktop] {
  margin: 0 auto;
  @include media("<=desktop") {
    display: none;
  }
}

[data-footer-menu] {
  display: flex;
  color: $color-white;
  background: $color-primary;
  width: 100%;
  height: auto;

  [data-footer-items] {
    @include media("<=desktop") {
      display: none;
    }

    @include media(">=tablet") {
      padding: 60px 0px;
    }

    @include media(">=desktop") {
      // width: 1246px;
      // margin: 0 auto;
      // padding: 60px 0px;
    }

    @include media("<=desktop") {
      padding: 25px 0px;
      flex-wrap: wrap;
    }

    [data-col-3] {
      @include media("<=desktop") {
        width: 100% !important;
        padding: 0px 25px;
      }

      @include media("<=tablet") {
        width: 50% !important;
        padding: 0px 25px;
      }
    }

    [data-footer-group] {
      width: 300px;

      [data-footer-group-title] {
        color: $color-white;
        font-size: 0.875rem;
        font-weight: bold;
        margin-bottom: 9px;
      }
    }
  }
}

[data-footer-group-items] {
  padding-bottom: 30px;
  flex-wrap: wrap;

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    & > li {
      line-height: 1.4;
      padding: 3px 0px;
      & > [data-footer-link] {
        padding: 6px 0px;
      }
    }
  }

  a {
    font-size: 0.875rem;
    font-weight: normal;
    color: rgba(112, 159, 196, 1);
  }

  font-size: 0.875rem;
  font-weight: bold;

  [data-footer-contacts] {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  [data-footer-social-media] {
    & > a {
      margin-right: 10px;
    }
  }
}
