@import "../../../../ds/variables";
@import "../../../../ds/media";

/**
 * Seção em destaque
 */
[data-destaques] {
  // background: url("../../../../assets/images/bg-home-destak.png") 0 0 no-repeat;
  // background-size: cover;
  height: 514px;
  width: 100%;
  padding-top: 40px;

  h2 {
    margin-bottom: 1em;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h3 {
    color: $color-white;
  }

  @include media("<=desktop") {
    h2 {
      font-size: 1.25rem;
      margin-bottom: 1.875rem;
    }

    > div {
      display: grid;
      grid-template-columns: repeat(1, 100%);
    }

    [data-quiz] {
      // margin-top: 2em;
    }
  }

  [data-home-grid] {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @include media(">=desktop") {
      display: grid;
      grid-template-columns: repeat(3, minmax(300px, 1fr));
      grid-template-rows: auto;
      gap: 1.5rem;
      flex-direction: none;
      min-height: auto;
    }

    .widgetDestaqueSemana {
      @include media(">=desktop") {
        height: 458px;
      }

      grid-column: 1;
      grid-row: 1 / 3;
    }

    .widgetWebcast {
      @include media(">=desktop") {
        height: 270px;
      }
      grid-column: 2;
      grid-row: 1 / 1;
    }

    .widgetPathways {
      @include media(">=desktop") {
        height: 130px;
      }
      grid-column: 2;
      grid-row: 2 / 3;
    }

    .widgetQuiz {
      @include media(">=desktop") {
        height: 458px;
      }
      grid-column: 3;
      grid-row: 1 / 3;
    }

    .widgetPodcast {
      @include media(">=desktop") {
        height: 130px;
      }
      grid-column: 3;
      grid-row: 2 / 3;
    }
  }
}
