@import "../../ds/variables";
@import "../../ds/media";

$area-color: (
  "conexao-de-ideias": linear-gradient(105deg, #77a595 0%, #217362 100%),
  "deep-dive": linear-gradient(105deg, #fd9727 0%, #cc4e0c 100%),
  "desafio-clinico": linear-gradient(105deg, #60e9ff 0%, #03a7c2 100%),
  "descomplicando-ciencia": linear-gradient(105deg, #a489ee 0%, #624aa6 100%),
  "einstein-practice": linear-gradient(105deg, #0287fa 0%, #0262b7 100%),
  "fast-challenge": linear-gradient(105deg, #f090f7 0%, #933c9a 100%),
  "opiniao-do-especialista": linear-gradient(105deg, #b1f864 0%, #64ab17 100%),
  "pilula-de-conhecimento": linear-gradient(105deg, #e389a8 0%, #d23b70 100%),
  "reuniao-cientifica": linear-gradient(105deg, #f9886c 0%, #f04b22 100%),
  "default": linear-gradient(105deg, #107b95 0%, #1bb28e 100%),
);

[data-limpa-filtro] {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-decoration-line: underline;
  color: #ffffff;

  cursor: pointer;
}

.oe-search-result-filters {
  background-size: cover;
  height: 100%;
  width: 100%;
  color: $color-white;
  position: relative;

  display: flex;
  padding: 0px;

  @include media("<=desktop") {
    // padding: 25px;
  }

  @each $name, $value in $area-color {
    &.#{$name} {
      h2 {
        padding-bottom: 0.2em !important;
      }
    }
  }
}

[data-row-media] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  [data-filter-area-conhecimento] {
    @include media("<=desktop") {
      width: 100%;
      margin-top: 1.5rem;
    }
  }
}

[data-filter-area-conhecimento] {
  label {
    font-size: 0.75rem !important;
    text-transform: uppercase;
    font-weight: 600;
  }
}

[data-empty] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}

[data-container-subinteresse] {
  // padding: 25px;

  @include media(">=desktop") {
    // padding: 40px calc((100% - 1246px) / 2);
  }

  & > div {
    [data-title] {
      color: $color-green-blue;
      margin: 0px 0px 28px 0px;
    }

    [data-item-patrocinador] {
      display: flex;
      flex-direction: column;
      width: 100%;

      .card-section {
        margin-bottom: 0px;
      }

      .card-swiper {
        margin-top: 0px;
      }

      [data-swiper-adapter] {
        overflow: hidden;
        width: auto;
        height: 290px;
        margin-left: 0px;
        padding: 0px;

        @include media(">=desktop") {
          padding: 36px 0 0;
          margin-left: -30px;
          position: absolute;
          height: 365px;
        }

        .swiper-button-prev,
        .swiper-button-next {
          background-color: rgba(255, 255, 255, 0.7);
          font-size: 10px;
          width: 36px;
          height: 36px;
          border-radius: 25px;
          color: $color-primary;

          &:after {
            font-size: 13.5px;
            font-weight: bold;
          }

          @include media("<=phone") {
            display: none;
          }
        }

        .swiper-button-prev {
          left: -18px;
        }
        .swiper-button-next {
          right: -18px;
        }

        .swiper-container {
          overflow: visible;
          height: 100%;
          @include media(">=desktop") {
            width: 400px;
            height: 300px;
            margin: 0px 0px 0px 29px;
          }
        }

        .swiper-pagination.swiper-pagination-bullets {
          width: 100%;
          bottom: -23px;

          @include media(">=desktop") {
            bottom: unset;
            top: -54px;
            left: 42%;
            width: auto;
          }
        }

        .swiper-pagination-bullets {
          bottom: 10px;
        }

        .swiper-pagination-bullet.swiper-pagination-bullet-active {
          opacity: 1;
          background-color: #fff;
          width: 10px;
          height: 10px;
        }

        .swiper-pagination-bullet {
          opacity: 0.5;
          margin: 0 10px !important;
          width: 8px;
          height: 8px;
          background-color: rgba(255, 255, 255, 0.8);
        }

        @include media("<=phone") {
          .destak-item {
            height: 150px;

            &__title {
              font-size: $font-sm !important;
              margin-bottom: 0.5rem;
              line-height: 1.3;
            }
            &__deep-dive {
              img,
              span {
                display: none;
              }
            }

            &__description {
              display: none;
            }

            &__footer {
              font-size: $font-xsm;
              img {
                max-width: 14px;
                max-height: 10px;
              }
              > div:first-child > div:first-child {
                border-width: 1px;
              }
            }
          }
        }
      }

      @include media("<=phone") {
        .card-section {
          &__link {
            position: absolute;
            right: 25px;
            margin-top: -25px;
          }
        }
      }
    }
  }
}
