.rpv-core__viewer {
  border: none !important;
  >div:first-child {
    background-color: #107B95 !important;
    color: white !important;
    height: 55px;
  }
}

.rpv-toolbar {
  padding-left: calc((100% - 1246px) / 2) !important;
  padding-right: calc((100% - 1246px) / 2) !important;
}

.rpv-core__minimal-button {
  color: white !important;
}

.rpv-toolbar__label {
  color: white !important;
  margin-left: 8px;
}

.rpv-core__viewer .rpv-core__viewer--light {
  height: 100vh !important;
}
