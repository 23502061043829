@import '../../../../ds/variables';

.higher-item {
    position: relative;

    [data-r] {
      display: flex;
    }

    &__header {
        background-size: cover !important;
        min-height: 300px;
        padding: 1.5rem;
        margin-bottom: 1.5rem;

        .tag {
            margin-right: 10px;
            margin-bottom: 8px;
        }

        &__title {
            color: $color-white;
            font-size: $font-med2;
            font-weight: 600;
            text-transform: uppercase;
            text-shadow: 0 0 1px $color-white;
        }

        &__date {
            color: $color-white;
            font-size: $font-sm;
            font-weight: $weight-semibold;
            position:relative;
            top: 2px;
            &--white {
                display: inline-block;
            }
            &--blue {
                display: none;
            }
        }
    }
    &__body {
        font-family: 'Open Sans';
        font-size: 14px !important;
        padding-bottom: 1rem;
        min-height: 96px;

        .button {
            margin: 1rem 0;
            width: 100% !important;
        }
    }
}
