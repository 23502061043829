@import '../../../ds/variables';
@import '../../../ds/media';

.conexao-de-ideias {
  background: linear-gradient(105deg, #217362 100%, #77a595 0%) !important;
}

.deep-dive {
  background: linear-gradient(180deg, #fd9727 0%, #ec8027 100%) !important;
}

.desafio-clinico {
  background: linear-gradient(180deg, #1daabf 0%, #1687a8 100%) !important;
}

.descomplicando-ciencia {
  background: linear-gradient(180deg, #8e2b9e 0%, #9c2681 100%) !important;
}

.einstein-practice {
  background: linear-gradient(180deg, #095390 0%, #065da1 100%) !important;
}

.fast-challenge {
  background: linear-gradient(105deg, #933c9a 100%, #f090f7 0%) !important;
}

.opiniao-do-especialista {
  background: linear-gradient(180deg, #72a933 0%, #8cc152 100%) !important;
}

.pilula-de-conhecimento {
  background: linear-gradient(180deg, #e62565 0%, #c61c54 100%) !important;
}

.reuniao-cientifica {
  background: linear-gradient(180deg, #fd9727 0%, #e65f29 100%) !important;
}

.cursos-online {
  background: linear-gradient(180deg, #02b28e 0%, #02b28e 100%) !important;
}

.pathways {
  background: linear-gradient(
    137.1deg,
    #673fb4 6.6%,
    #9b2fae 98.07%
  ) !important;
}
.default {
  background: linear-gradient(105deg, #107b95 0%, #1bb28e 100%) !important;
}

[data-header-section] {
  background: url("../../../assets/images/bg-home-destak.png") 0 0 no-repeat;
  background-size: cover;
  width: 100%;
  color: $color-white;
  display: flex;
  min-height: 155px;

  @include media('>=desktop') {
    min-height: 125px;
  }

  [data-header-section-inner] {
    width: 100%;
    padding: 25px;

    @include media('>=desktop') {
      width: 1246px;
      margin: 0 auto;
      padding: 0px;
    }

    h2 {
      padding: 0px;
      margin-bottom: 0;
      font-family: $font-family-monserrat;
      font-weight: $weight-bold;

      @include media("<=desktop") {
        font-size: 1.25rem;
      }

      @include media('>=desktop') {
        margin-top: 36px;
        margin-bottom: 0px;
      }
      
      line-height: 1;
    }

    h3 {
      font-family: $font-family-monserrat;
      font-style: normal;
      font-weight: $weight-bold;
      font-size: 22px;
      line-height: 27px;
      color: $color-white;
    }

    [data-header-section-sub-title] {
      font-weight: $weight-semibold;
      font-size: 1rem;
      margin: 0px 0px 0px 0px;

      @include media('>=desktop') {
        font-size: 1.375rem !important;
      }
    }
  }
}
