@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-pdf-preview-mobile] {
  display: flex;
  width: 100%;
  flex-direction: column;

  [data-image-preview-mobile] {
    width: 100%;
    height: 200px;
    background-size: cover;
    padding: 14px 32px 0 32px;
    flex-direction: column;
    justify-content: space-between;
  }

  [data-pdf-status-mobile] {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
  }

  [data-pdf-info-mobile] {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 10px 10px 0 0;
    margin-top: -15px;
    background-color: $color-white;
    // z-index: 999;

    [data-all-video-audio] {
      @include media("<=desktop") {
        width: 100%;
      }
      margin-top: 15px;
      select {
        color: #000;
        border-bottom: 1px solid #000;
      }
    }

    margin-right: 0px;
    width: 100%;

    h1 {
      color: $color-green-blue;
      font-size: 30px;
      font-weight: $weight-bold;
      width: 100%;
      margin-top: 18px;

      font-size: 16px;

      > span {
        margin-right: 20px;
      }
    }

    [data-pdf-recommended-mobile] {
      width: 100%;
      line-height: 1.3;
      margin-top: 24px;

      .tags {
        > span {
          background-color: #9a0b83;
          color: #fdfeff;
        }
      }

      margin-bottom: 0px;
    }

    [data-pdf-info-time] {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 24px;

      [data-pdf-info-icon] {
        padding: 1px 2px 1px 2px;
        border: 2px solid #107b95;
        border-radius: 8px;
        color: #107b95;
        font-weight: 600;
        font-size: 7px;
      }

      [data-pdf-info-icon-img] {
        margin: 0px;
        width: 16px;
        height: 16px;
      }

      [data-pdf-info-type] {
        margin-left: 5px;
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #707070;
      }

      [data-pdf-info-icon-date] {
        margin: 0px 0px 0px 10px;
        width: 16px;
        height: 16px;
      }
    }

    [data-pdf-info-palavra-chave] {
      margin-top: 25px;

      [data-pdf-info-palavra-chave-title] {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #707070;
      }

      [data-pdf-info-tags] {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .tags {
          > span {
            background-color: #e7f2f4;
            color: #107b95;
            font-weight: 700;
            font-size: 9px;
            line-height: 11px;
          }
        }

        [data-pdf-info-ver-mais] {
          cursor: pointer;
          display: flex;
          [data-pdf-info-icone-ver-mais] {
            margin: 0px;
            width: 24px;
            height: 24px;
          }

          [data-pdf-info-icone-ver-mais-label] {
            font-family: $font-family-monserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #0a589b;
            margin-left: 12px;
            margin-top: 5px;
          }
        }
      }
    }

    [data-pdf-content-mobile] {
      width: 100%;
      height: auto;
      margin-right: 30px;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-color: #9b9b9b #f4f4f4;
      scrollbar-width: thin;
      margin-top: 32px;

      * {
        line-height: 1.5;
      }

      [data-pdf-content-desktop-recomendado] {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #707070;
      }

      p {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #707070;

        margin-top: 12px;
      }

      @include media("<=desktop") {
        height: auto;
        width: 100%;
      }

      img {
        @include media("<=desktop") {
          width: 100%;
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        background: #f4f4f4;
        border: solid 2px #e4e4e4;
        border-radius: 0px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 0px;
        box-shadow: inset 0 0 6px #9b9b9b;
        -webkit-box-shadow: inset 0 0 6px #f4f4f4;
      }

      &::-webkit-scrollbar-thumb {
        margin: 2px;
        border-radius: 0px;
        position: absolute;
        background: #107b95;
        border: 2px solid #e4e4e4;
      }
    }

    .button {
      margin-top: 2rem;
      background-color: $color-green-blue;
      height: 40px;
      @include media("<=desktop") {
        width: 80%;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
      }
    }
  }
}

[data-preview-play] {
  position: absolute;
  left: 41%;
  top: 20%;

  .play-button {
    cursor: pointer;
    width: 40px !important;
    height: 40px !important;
    display: block;
    text-align: center;
    margin: 30px auto;

    img {
      width: 60px;
      height: 60px;
    }

    &.hide-for-mobile {
      display: none !important;
    }
  }
}

[data-live-img-mobile] {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 200px;
}

[data-close-mobile] {
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  background-color: #00498d;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    fill: #ffff;
  }
}

[data-preview-date-mobile] {
  border: 1px solid #dce1e5;
  border-radius: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  border-radius: 50px;

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    color: #707070;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-right: 4px;
  }

  strong {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
  }
}

[data-preview-ler-agora-mobile] {
  background: #00498d;
  cursor: pointer;
  border-radius: 22.5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  margin-top: 20px;

  span {
    text-align: center;
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    color: #fdfeff;
  }

  strong {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
  }
}
