@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");

body {
  /* webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: "Montserrat", sans-serif;

  scroll-behavior: smooth !important; */
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

@layer base {
  html,
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Montserrat", sans-serif !important;
  }

  html {
    scroll-behavior: smooth;
  }
}

.vp-center div iframe {
  border-radius: 8.84px !important;
}

.player-video-multiplos {
  width: 100%;
  @media (min-width: 768px) {
    min-height: 470.14px;
    border-radius: 12px;
  }
  height: 100%;
  overflow: hidden;
  iframe {
    width: 100%;
    height: 100%;
    @media (min-width: 768px) {
      border-radius: 12px;
    }
  }
}

.player-video-preview {
  iframe {
    width: 100%;
    @media (min-width: 768px) {
      height: 100%;
    }
  }
}

.swiper-pagination-bullet {
  --swiper-pagination-bullet-size: 6px;
}

.swiper-button-next,
.swiper-button-prev {
  --swiper-navigation-size: 17px !important;
  top: 50%;
  bottom: 50%;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  background-color: #02b28e;
  --swiper-navigation-color: white;
}

.swiper-pagination {
  --swiper-pagination-color: #02b28e;
}

/* .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -2px !important;
} */

.MuiSlider-root,
.fMuiSlider-colorPrimary,
.MuiSlider-sizeSmall,
.css-9c6t48 {
  padding: 2px 0 !important;
  margin-top: 5px;
  margin-bottom: 8px;
}

.scroll-residencia {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00b193;
    border-radius: 5px;
  }
}

.react-international-phone-input-container {
  margin-top: 0px !important;
  border-bottom: 1px solid #cccc !important;
}

.svg-white {
  svg {
    color: white;
  }
}

.react-international-phone-input-container .react-international-phone-input {
  /* border-bottom: 1px solid #e5e5e5 !important; */
  border: 0px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border: 0px;
}

.react-international-phone-input-container {
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.icon-color {
  svg {
    color: #02b28e;
  }
}

.gradiente-einstein {
  @apply bg-gradient-to-r from-[#107B95] to-[#1BB28E] bg-clip-text text-transparent;
}

.destaque-query {
  @media (min-width: 768px) and (max-width: 1200px) {
    margin-left: 4rem;
  }
}

.svg-white {
  svg {
    color: white;
  }
}

.skip-to-content {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  transition: top 0.3s;
}

.skip-to-content:focus {
  top: 0;
}
