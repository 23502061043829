@import "../../../../ds/media";
@import "../../../../ds/variables";

[data-modal-confirma-perfil-medico]{
    display: flex;
  flex-direction: column;
  margin: auto;

  @include media("<=desktop") {
    width: 100%;
  }

  h3 {
    justify-content: center;
    color: $color-primary;
    text-align: center;
    text-transform: none;

    @include media("<=desktop") {
      text-align: center;
    }
  }

  p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.3;

    @include media("<=desktop") {
      padding: 0;
    }
  }

    [data-modal-confirma-perfil-medico-footer]{
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;

        button{
            width: 160px;
            height: 35px;
            padding: 8px 0px;
        }
    }
}
