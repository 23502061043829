@import "../../../../ds/media";

$default-section-padding: 0 calc((100% - 1246px) / 2);// 63px 32px;

[data-edit-profile] {
  display: flex;
  flex-direction: row-reverse;

  [data-loader] {
    background-color: white;
    position: absolute;
    img {
      mix-blend-mode: difference;
    }
  }

  h4 {
    color: #0A589B;
    font-size: 24px !important;
    font-weight: 600;
    margin-bottom: 1em;
    text-align: center;
  }

  > nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 3em;
    margin-left: 2em;
    margin-top: 3.18em;
    border-right: 1px solid rgba(112, 112, 112, 0.3);

    a {
      color: #5C6268;
      padding: 1em;
      text-align: right;

      &.active {
        color: #0A589B;
        font-weight: 600;
      }

      &:nth-of-type(2) {
        border-bottom: 1px solid rgba(112, 112, 112, .3);
        border-top: 1px solid rgba(112, 112, 112, .3);
      }
    }
  }

  > section {
    box-shadow: none;
    flex-grow: 8;
    padding: 1em;
  }

  @include media('<=desktop') {
    > nav {
      display: none;
    }

    > section {
      max-width: 100%;

      [data-professional-data],
      [data-preferences-data] {
        margin-top: 2em;
      }

      form fieldset {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }
}