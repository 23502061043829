@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-meus-autores] {
  display: flex;
  flex-direction: column;
  padding: 0px;
  color: $color-green-blue;
  margin-top: 40px;

  @include media("<=desktop") {
    margin-top: 60px !important;
  }

  @include media("<=phone") {
    margin: 0px 36px;
  }

  [data-row][data-titulo-meus-autores] {
    margin-bottom: 27px;
  }

  [data-row] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    [data-link-primary] {
      margin: 0px !important;
    }

    [data-card] {
      width: 100% !important;
      box-shadow: 0 3px 6px #00000029;
      padding: 0px !important;
      height: 385px;
      margin-bottom: 32px;

      @include media(">=desktop") {
        padding: 0px;
        height: 258px;
        margin-bottom: 52px;
      }

      @include media("<=phone") {
        padding: 10px;
      }
    }
  }
}
