@import "../../../../ds/media";
@import "../../../../ds/variables";

[data-modal-permitidos] {
  animation: fadeIn 233ms ease 233ms;
  animation-fill-mode: both;
  background-color: white;
  border-radius: 8px;
  height: fit-content;
  left: 50%;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: fit-content;
  z-index: 2000;

  @include media("<=desktop") {
    height: 360px;
    width: 100%;
    border-radius: 0px;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    transform: initial;
    display: flex;
    align-items: center;
    justify-content: center;

    [data-modal-optin] h1 {
      margin-top: 0;
    }
  }

  &.fullscreen {
    height: 100vh;
    left: 0;
    overflow: auto;
    top: 0;
    transform: unset;
    width: 100vw;
  }

  &.halfscreen {
    height: 80vh;
    width: 70vw;
    display: flex;
    justify-content: center;

    margin: auto;
    overflow: none;
  }
}

[data-modal-acesso-permitido-logados] {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 664px;

  @include media("<=desktop") {
    width: 100%;
    padding: 0 18px;
  }

  h3 {
    justify-content: center;
    color: $color-primary;
    margin: 60px 40px 11px 40px;
    text-align: center;
    text-transform: none;

    @include media("<=desktop") {
      text-align: center;
      margin: 10% 0px 11px 0px;
    }
  }

  [data-modal-acesso-permitido-logados-footer] {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    button {
      width: 160px;
      height: 35px;
      padding: 8px 0px;
      margin: 1rem 1rem 1rem 1rem;
      @include media("<=phone") {
        margin: 0.5rem 0.2rem 0.5rem 0.5rem;
      }
    }
  }

  a {
    font-size: 0.85rem !important;
    text-align: center;
    margin-bottom: 2rem;
    text-decoration: underline;
  }
  a:hover {
    color: $color-light-blue;
  }
}
