@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-person-information] {
  .form-base {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__title {
      @include media("<=desktop") {
        margin-top: 2rem;
      }

      @include media(">desktop") {
        margin-bottom: 2rem;
      }
    }

    form {
      display: flex;
      width: 100%;
      flex-direction: column;

      [data-form-wrapper] {
        display: flex;
        flex-direction: row;
        width: 100%;

        @include media("<=desktop") {
          display: inline !important;
        }

        [data-col] {
          display: flex;
          flex-direction: column;
          width: 50%;

          @include media("<=desktop") {
            width: 100% !important;
          }

          .MuiFormControl-root,
          .otherDocument {
            width: 100%;
            position: relative;
            margin-top: 1rem;

            .MuiFormLabel-root {
              font-weight: bold !important;
              font-size: 1rem;
              color: $color-default;
              font-family: $font-family-monserrat;

              &.MuiFormLabel-root.Mui-error {
                color: #f44336;
              }

              .MuiInputBase-root {
                input[type="date"]::before {
                  color: lightgrey;
                  content: attr(placeholder) !important;
                  margin-right: 0.5em;
                  background-color: aqua;
                  color: red !important;
                }
              }
            }

            .MuiFormHelperText-root {
              @include media(">=desktop") {
                position: absolute;
                bottom: -20px;
              }
            }
          }

          .MuiFormControl-root:not(:first-child) {
            margin-top: 2rem;
          }

          &:first-child {
            padding-right: 1rem;
          }

          &:last-child {
            margin-top: 0 !important;
            padding-left: 1rem;
          }

          .field-link {
            display: flex;
            align-self: flex-end;
            font-size: 12px !important;
            font-weight: normal;
            color: #0a589b;
            text-decoration: underline;
            cursor: pointer;
            // -webkit-appearance: button;
            // box-sizing: border-box;
            // -webkit-font-smoothing: antialiased;
            padding-bottom: 0 !important;
            padding-top: 1rem;
          }
        }

        [data-col]:last-child {
          @include media("<=desktop") {
            padding-left: 0 !important;
          }
        }
      }

      [data-form-footer] {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;

        @include media("<=desktop") {
          margin-top: 2rem;
        }

        [data-not-register] {
          font-size: 14px;
          margin: 0;

          [data-mailing] {
            color: $color-default !important;
            text-decoration: underline;
            font-weight: bold;

            &:active {
              color: $color-default !important;
            }
          }
        }
      }
    }
  }
}
