@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-categorias-conteudo] {

  [data-card] {
    position: relative;
    width: 100% !important;
    height: 200px !important;
    box-shadow: 0 3px 6px #00000029;
    text-align: center;
  }

  [data-card-content] {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      max-height: 100px;
    }

    h4 {
      font-size: $font-larg !important;
      font-family: $font-family-monserrat;
      font-weight: $weight-semibold;
      color: $color-white;
      text-transform: uppercase;
    }
  }

  [data-card-footer] {
    flex-direction: column;

    @include media(">phone") {
      margin-top: 16px;
    }

    p {
      margin: 0px;
      font-size: 0.875rem;
      color: $color-default;
      font-family: $font-family-opensans;
    }
  }

  @include media(">=desktop") {
    width: 1246px;
    margin-top: 3%;
    padding-right: 0;
    padding-left: 0;

    [data-card] {
      margin-top: 5%;
    }

    [data-card-footer] {
      p{padding: 1em;}
    }

  }

  @include media("<=phone") {
    [data-card]{
      margin-top: 3em !important;
    }
  }
}
