@import "../../../../ds/variables";
@import "../../../../ds/media";


.grid__item {
  align-items: center;
  background: $color-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 11em;
  margin: auto;
  width: 171px;
  padding: 5px 10px;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;

  >img {
    margin-bottom: 8px;
  }

  @include media("<=tablet") {
    height: 131px;
    width: 139px;
    margin-bottom: 17px;

    img,
    span {
      zoom: 0.7;
    }



    span {
      font-weight: 600;
    }
  }

  &:focus+.grid__description {
    max-height: 200px;
    transition: max-height 0.4s ease-in;
  }
}

.grid__item-is-selected {
  box-shadow: none;
  background: #f2f2f4;
}

li {
  list-style: none;
  justify-self: flex-start;
}

:focus {
  outline: 0;
}

.fullwidth {
  grid-column: 1 / -1;
}

.is-hidden {
  display: none !important;
}

.fullwidth,
.is-selected {}

[data-opcoes-area-conhecimento] {
  margin-top: 3px;
  width: 100%;

  .data-group-list {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

  @include media("<=tablet") {
    padding: 0 11px 0 11px;
  }

  @include media("<=phone") {
    padding: 0 11px 0 11px;
  }

  [data-title-publico-alvo] {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: $color-green-blue;
  }

  ul {
    margin-top: 16px;
    width: 100%;
 
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;

    @include media("<=tablet") {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }

    @include media("<=phone") {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }

  }
}