@import "../../../ds/variables";
@import "../../../ds/media";


[data-authors-container] [data-card] {
  @include media("<=phone") {
    margin-bottom: 36px !important;
    // flex-wrap: wrap;
    //padding: 0px 0px 31px 20px;
  }
}

[data-author-box] {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 55px;

  @include media("<=phone") {
    flex-direction: column;
    margin-bottom: 10px;
    // flex-wrap: wrap;
    //padding: 0px 0px 31px 20px;
  }

  [data-author-photo] {
    display: flex;
    align-items: center;

    @include media(">=desktop") {
      margin-right: 20px;
    }

    @include media("<=desktop") {
      min-height: 143px;
      width: auto;
    }

    @include media("<=phone") {
      margin-top: 31px;
    }

    & > div {
      width: 163px;
      height: 163px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
    }
  }

  [data-author-info] {
    color: $color-primary;
    line-height: 1.3;

    h3 {
      @include media("<=desktop") {
        font-size: 1em !important;
        margin-top: 0px;
        margin-bottom: 0.9375em !important;
      }

      @include media("<=phone") {
        text-align: center;
        margin: 16px 0px 3px 0px !important;
      }
    }

    [data-author-cargo] {
      display: flex;
      flex-direction: column;

      @include media("<=desktop") {
        margin-bottom: 20px;
      }

      @include media("<=phone") {
        margin-bottom: 10px;
      }

      h4 {
        font-size: 1rem;
        margin-bottom: 13px;
        color: $color-primary;

        @include media("<=desktop") {
          font-size: 0.85em;
          margin-top: 0px;
          margin-bottom: 10px;
        }

        @include media("<=phone") {
          margin: 0px 0px 12px 0px;
          text-align: center;
        }
      }

      [data-author-contact] {
        [data-author-item] {
          display: flex;
          align-items: center;

          @include media("<=phone") {
            display: flex;
            align-items: center;
            justify-content: center;

            &:not(:last-child) {
              margin-bottom: 6px;
            }
          }

          > span {
            font-size: $font-normal;
            color: $color-primary;
            font-family: $font-family-opensans;
            font-weight: $weight-bold;
          }

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        [data-author-email] {
          > span {
            text-transform: lowercase;
          }
        }

        [data-author-projeto] {
          > span {
            text-transform: lowercase;
          }
        }

        [data-author-link] {
          > span {
            text-transform: capitalize;
          }
        }
      }
    }

    [data-author-content-published] {
      margin: 0px 0px 16px 0px;
      text-transform: capitalize !important;
      display: flex;
      align-items: center;

      @include media("<=desktop") {
        margin-bottom: 18px;
      }

      @include media("<=phone") {
        margin: 0px 0px 18px 0px;
        justify-content: center;
      }

      > img {
        margin-right: 10px;
      }

      > span {
        font-size: 14px;
        color: $color-default;
        font-weight: $weight-bold;
      }
    }
  }

  [data-author-buttons] {
    display: flex;
    justify-content: stretch;
    width: 100%;

    @include media("<=desktop") {
      justify-content: center;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-weight: $weight-semibold;
      min-height: 40px;

      @include media(">=desktop") {
        width: 186px;
        height: 36px;
        padding: 0px;
      }
    }

    & > .button-primary {
      margin-right: 12px !important;
    }
  }
}
