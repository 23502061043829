@import "../../../../ds/media";

[data-nao-concluido] {
  span {
    color: #abcad3;
  }
}

[data-concluido],
[data-nao-concluido] {
  span {
  }
}
