$color-white: #fff;

$color-default: #5c6268;
$color-gray: #838383;

$color-gray-light: #adadad;

$color-primary: #0a589b;
$color-light-blue: #1eaaf1;
$color-blue: #112bbd;

$color-dark-green: #096c63;
$color-light-green: #02b28e;
$color-green-seconday: #8cc152;

$color-dark-red: #ac2e00;
$color-light-red: #e91e1e;

$color-orange: #fd9727;
$color-orange-dark: #fc5830;
$color-purple: #9b2fae;
$color-light-purple: #9a0b83;
$color-dark-purple: #673fb4;
$color-rose: #e62565;

$color-green-blue: rgba(16, 123, 149, 1);
$color-light-green-blue-transparent: rgba(16, 123, 149, 0.15);

$color-card-oe-patrocinada: #6d6e70;

$font-xsm: 0.625rem; // 10px
$font-sm: 0.75rem; // 12px
$font-normal: 0.875rem; // 14px
$font-med2: 1rem; // 16px
$font-med: 1.125rem; // 18px
$font-med1: 1.375rem; // 22px
$font-med-larg: 1.5rem; // 24px
$font-larg: 1.75rem; // 28px
$font-xlarg: 2.5rem; // 40px

$font-family-monserrat: "Montserrat", sans-serif;
$font-family-opensans: "Open Sans", sans-serif;

$weight-bold: bold;
$weight-semibold: 600;
$weight-normal: normal;
$weight-light: 200;

$button-default-hover: #3868c6;
$button-default-focus: #2b59b6;

$button-secondary-hover: #3868c6;
$button-secondary-focus: #3868c6;

$button-option-hover: #f3f2f2;

// $default-section-padding: 63px calc((100% - 1246px) / 2);

$default-section-padding-side: calc((100% - 1246px) / 2);

$colors: (
  white: $color-white,
  blue: $color-primary,
  green: $color-green-blue,
  gray: $color-default,
);
