@import "../../../../ds/media";
@import "../../../../ds/variables";

[data-modal-conclusao] {
  padding: 1em;

  width: 100%;
  height: 100%;

  @include media(">=phone") {
    width: 530px;
    // height: 269px;
    padding: 1em;
  }

  h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $color-primary;
    margin: 100px 30px 20px 30px;
    text-align: center;
    line-height: 1.3;

    @include media(">=phone") {
      margin: 37px 0px 20px 0px;
    }

    @include media("<=desktop") {
      margin-top: 0;
    }
  }

  [data-row] {
    display: flex;
    flex-direction: column;

    @include media(">=phone") {
      flex-direction: row;
    }

    p {
      font-size: 0.9375rem;

      text-align: center;
      line-height: 1.3;
      margin: 0 10px;

      @include media(">=phone") {
        margin: 0 61px 33px 61px;
      }
    }
  }

  [data-row][data-flex-row-arround] {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    @include media(">=phone") {
      margin-top: 0;
      padding: 0 75px;
    }

    button {
      width: 100%;
      margin-bottom: 16px;

      @include media(">=phone") {
        width: 162px;
      }
    }
  }

  [data-row][data-flex-row-center] {
    justify-content: center;
    margin-top: 20px;
  }

  @include media(">=phone") {
    margin-top: 0;
    padding: 30px 20px 52px 20px;
  }

  button {
    width: 100%;
    margin-bottom: 16px;

    @include media(">=phone") {
      width: 162px;
    }
  }
}
