@import '../../ds/variables';
@import '../../ds/media';

.oe-search-page {

  header {
    margin-bottom: 2rem;
  }

  .oe-search-result, .oe-search-error {
    padding: 0 30px;
    @include media('>=desktop') {
      width: 1246px;
      margin: 0 auto;
      padding: 0;
    }
  }

  .oe-search-error {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  [data-banner-rodape] {
    margin-bottom: 32px;
  }

  [data-total-conteudo] {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #4A4A4A;
    @include media(">tablet") {
      position: absolute;
      margin-top: 5px;
    }
  }
 }
