@import "../../../ds/variables";
@import "../../../ds/grid";
@import "../../../ds/media";

[data-card] {
  background: $color-white; // default
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &[data-card*="shadow"] {
     box-shadow: 0px 3px 6px #00000029;
  }

  [data-card-content], header {
    padding: 1em;
  }

  header + [data-card-content] {
    padding-top: 0;
  }

  [data-card-content] {
    align-items: center;
    display: flex;
  }

  footer {
    box-shadow: 0px 0px 13px $color-default;
  }
}
