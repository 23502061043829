@import '../../../ds/variables';

[data-time-view] {
  align-items: center;
  display: flex;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;


  @each $name, $value in $colors {
    &[data-time-view*="#{$name}"] {
      color: $value !important;
    }
  }

  > img {
    margin-right: .3em;
  }
  > div {
    margin-left: 0 !important;
    white-space: nowrap;

    > span {
      display: inline-block !important;
      margin-left: 0 !important;

      &.space {
        padding: 0 .2em;
      }
    }
  }

  &[data-animate="true"] {
    > div > span {
      animation: fadeInDown 433ms ease;
      animation-fill-mode: both;

      @for $i from 1 to 10 {
        $delay: $i * 133;

        &:nth-child(#{$i}) {
          animation-delay: #{$delay}ms;
        }
      }
    }
  }

  [data-color] {
    @each $name, $value in $colors {
      &[data-color*="#{$name}"] {
        color: $value !important;
      }
    }
  }
}
