@import '../../../ds/variables';

.form-base {
    padding: 0;
    border-radius: 0px;

    &__title {
        font-size: $font-med;
        font-weight: $weight-bold;
        color: $color-primary;
    }
}

form {
    width: 100%;
    fieldset {
        .field-group {
            width: 100%;
            margin-bottom: 36px;
            label {
                display: flex;
                width: 100%;
                color: $color-default;
                font-size: $font-sm;
                font-weight: $weight-bold;
                text-transform: uppercase;
            }
        }
        
        .field {
            
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px $color-default;

            &-error {
                padding-top: 6px;
                color: red;
                font-size: 12px;
                display: flex;
                width: 100%;
                text-align: left;
            }

            input {
                display: flex;
                background: transparent;
                border: none;
                outline: none   ;
                width: 100%;
                padding: 9px 0px 9px 5px;
                font-size: $font-sm;
                font-weight: $weight-normal;
                :focus {
                    border: none;
                }
                :active {
                    border: none;
                }
            }
        }
    }
}