@import "../../ds/variables";
@import "../../ds/media";

[data-sobre-page]{
    font-family: $font-family-monserrat;
    width: 100%;
    padding: 25px;

    article{
        margin-top: 3rem;
    }

    .sobre{
        margin-top: 5%;
        p{
            margin-top: 2em;
        }

        /*
        iframe{
            margin:0!important;
            width:100%;
            padding: 0!important;
            background-color: aqua;

            .vp-center{
                margin:0 !important;
            width:100%;
            padding: 0!important;
            background-color: black !important;
            }
        }
        */

    }

    .categorias-conteudo{
        margin-top: 8%;
        p{
            margin-top: 1em;
        }
    }

    .faca-parte{
        margin-bottom: 5%;
    }

    h2{
        color: $color-green-blue ;
        font-weight: $weight-bold;
    }

    .player-video {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include media('>=desktop') {
        width: 1246px;
        margin: 0 auto;
        padding: 0px;

        h2{
            margin-bottom: 0px;
            font-size: 1.125rem ;
        }

        .faca-parte{
            .button{
                width: 30%;
            }
        }

    }


    @include media("<=desktop") {
        
        h2{font-size: 0.875rem;}

        .categorias-conteudo{
            margin-top: 40px;
        }

        .faca-parte{
            margin-top: 3em;
            margin-bottom: 3em;
            .button{
                width: 100%;
            }
        }
    }

}