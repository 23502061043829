@import "../../../ds/variables";
@import "../../../ds/media";

[data-tema] {

  h5 {
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #107B95;
    margin-bottom: 17px;

    @include media("<=phone") {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  li, a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #707070;
    cursor: pointer;

    @include media("<=phone") {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }
    
  }

  ul {
    margin-bottom: 29px;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;

    @include media("<=tablet") {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }

    @include media("<=phone") {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  }
}