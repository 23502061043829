@import "../../../ds/variables";
@import "../../../ds/media";

[data-search-area] {
  position: relative;
  input {
    width: 100%;

    &::-webkit-input-placeholder {
      /* Edge */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
    }

    &::placeholder {
    }
    &.field-control {
      background: none;
      border-width: 1px;
      padding: 0.4rem 1.4rem 0.4rem 0;

      @include media("<=desktop") {
        width: 100%;
      }
    }

    :focus {
      border: none;
    }
  }
  .icon-search {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
