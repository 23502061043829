@import "../../ds/variables";
@import "../../ds/media";

[data-professions-page] {
  [data-banner] {
    margin: 0 auto;
  }

  [data-banner][data-banner-cabecalho] {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  [data-banner][data-banner-rodape] {
    margin: 40px auto;
    width: 800px;
    height: 150px;

    @include media("<=phone") {
      width: 250px;
      height: 150px;
      justify-content: center;
    }
  }
}

[data-professions-container] {

  margin: 50px 0px;

  @include media("<=phone") {
    margin: 50px 10px;
  }

  @include media("<=tablet") {
    margin: 50px 20px;
  }

  @include media(">=desktop") {
    padding: 40px calc((100% - 1246px) / 2);
    padding-top: 0px;
    padding-bottom: 0px;
  }


  [data-title] {
    @include media(">=desktop") {
      padding: 40px calc((100% - 1246px) / 2);
      padding-top: 0px;
      padding-bottom: 0px;
    }

    margin: 50px 50px;

    @include media("<=phone") {
      margin: 50px 10px;
    }
  
    @include media("<=tablet") {
      margin: 50px 20px;
    }
  

    color: $color-green-blue;
    margin: 0px 0px 28px 0px;
  }

  @include media("<=desktop") {
    margin-bottom: 43px;

    h3 {
      zoom: 0.7;
    }
  }

  h3 {
    color: $color-white;
  }
}