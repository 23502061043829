@import "../../ds/variables";
@import "../../ds/media";

$default-section-padding: 63px calc((100% - 1246px) / 2); // 63px 32px;

[data-certificate] {
  // padding: $default-section-padding;

  [data-actions] {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;

    button:not(:last-child) {
      // margin-right: 2em;
    }
  }

  [data-corpo-certificado] {
    align-items: center;
    border: 1px solid #222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1246px;
    overflow: hidden;
    position: relative;
    zoom: 80%;
    @include media("<=phone") {
      height: 504px;
    }

    @include media("<=desktop") {
      zoom: 33%;
    }

    &:after {
      background-image: linear-gradient(
        to left,
        #00be54 10%,
        $color-light-green
      );
      content: " ";
      height: 100%;
      right: -20%;
      position: absolute;
      top: -93%;
      transform: rotate(5deg);
      width: 100%;
    }

    &:before {
      background-image: linear-gradient(
        to right,
        #00be54 20%,
        $color-light-green
      );
      content: " ";
      height: 100%;
      left: -20%;
      position: absolute;
      top: 93%;
      transform: rotate(5deg);
      width: 100%;
    }

    header {
      display: flex;
      flex-direction: column;

      > img {
        align-self: center;
      }

      h1 {
        color: #00b388;
        margin-top: 3rem;
        margin-bottom: 3rem;
        font-family: $font-family-opensans !important;
        font-weight: bolder;
        letter-spacing: 0.1rem;
      }
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 1.125rem;
      line-height: 1.6;
      text-align: center;

      > .user-name {
        color: $color-primary;
        font-weight: bolder;
        font-size: 2rem;
        font-family: $font-family-monserrat !important;
        //font-size: 1.8em;
        border-bottom: 2px solid #00b388;
      }
    }
  }

  footer {
    align-items: flex-end;
    display: flex;
    margin-top: 3em;
    width: 100%;

    [data-conclusao] {
      padding: 1em 3em;
      position: relative;
      align-self: flex-start;
      margin-top: 1rem;

      &:after {
        content: " ";
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 200%;
        z-index: -1;
      }
    }

    [data-signature] {
      text-align: center;
      align-self: flex-start;

      strong {
        text-transform: uppercase;
      }
    }
  }
}
