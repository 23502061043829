@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-compartilhamento] {
  [data-compartilhar] {
    color: $color-green-blue;

    @include media("<=phone") {
      img {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  [data-menu-midias-sociais] {
    top: 0 !important;
  }
}

.group-share-buttons {
  .MuiPaper-root {
    margin-top: -145px;
    overflow-x: inherit;
    overflow-y: inherit;

    @include media("<=desktop") {
      margin-top: -72px;
      margin-left: -65px;
    }
  }

  .MuiList-root {
    position: relative;

    .live-after {
      .MuiPaper-rounded {
        left: 10% Im !important;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 44%;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 17px solid $color-white;
      clear: both;
    }

    &.MuiList-padding {
      padding: 0;
    }
  }

  .MuiMenuItem-root {
    &.MuiListItem-gutters {
      padding: 0;
      width: 100%;
    }
  }

  .wrapper-share-button {
    padding: 0.45rem 1.35rem !important;
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;

    .text {
      font-family: "Open Sans", sans-serif;
      color: $color-gray;
      font-size: 1rem;
    }

    &.wrapper-facebook-share-button {
    }

    &.wrapper-linkedin-share-button {
    }

    &.wrapper-link-share-button {
    }
  }
}
