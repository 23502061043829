[data-professional-data] {
  .MuiFormLabel-root {
    font-weight: bold !important;
    font-size: 1rem;

    &.MuiFormLabel-root.Mui-error {
      color: #f44336;
    }
  }

  .MuiInputBase-root {
    &.MuiInput-underline.Mui-error {
      &:after {
        border-color: #f44336;
      }
    }
  }

  .MuiFormHelperText-root {
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;

    &.Mui-error {
      color: #f44336;
    }
  }
}
