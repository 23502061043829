@import "../../../ds/variables";
@import "../../../ds/media";
.card-section {
  // padding: $default-section-padding;
  padding-top: 0;
  padding-bottom: 0;
  &__bullets {
    display: flex;
  }
  &__bullet {
    width: 8px;
    height: 8px;
    background-color: $color-light-green-blue-transparent;
    border-radius: 5px;
    margin-right: 1rem;
    &--active {
      width: 10px;
      height: 10px;
      background-color: $color-green-blue;
      margin-top: -1px;
    }
  }
  &__title-bar {
    > div {
      display: flex;
      align-items: center;
    }
    display: flex;
    justify-content: space-between;
    &__title {
      white-space: pre-line;
      color: $color-green-blue;
      margin-right: 1rem;
      margin-bottom: 0px;
    }
    &__icon {
      margin-top: -4px;
      margin-right: 15px;
    }
  }
  &__link {
    font-size: $font-normal;
    color: $color-green-blue;
    font-weight: $weight-semibold;
    @include media("<=desktop") {
      font-size: $font-sm;
      //display: none;
      display: flex;
    }
  }
}