@import "../../../ds/variables";
@import "../../../ds/media";

.media-card {
  border-radius: 0.5rem;
  position: relative;
  background-clip: padding-box;
  background-position: center;
  background-size: cover;
  color: $color-white;
  height: 100%;
  min-height: 275px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  // box-shadow: 0px 3px 6px #00000029;

  &__body {
    margin: 15px;
    height: 222px;

    h3 {
      word-wrap: break-word;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: start;
    text-align: center;
    flex-direction: column;
    span {
      display: flex;
      text-align: center;
      align-items: center;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13.41px;
      margin-top: 12px;
    }
  }

  &__badges {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 14px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: $font-med2;
    font-weight: $weight-bold;
    // text-transform: uppercase;
    width: 65%;
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    line-height: 20px;
  }
}

[data-media-card].oe-patrocinada {
  .media-card {
    // border-radius: 0.625rem;
    min-height: 222px !important;
  }

  [data-oe-footer] {
    background-color: $color-card-oe-patrocinada;
    margin-bottom: 0;
  }

  [data-footer-oe-patrocinada] {
    position: relative;
  }
}
