@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-history-content] {
  [data-no-content] {
    > div {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      h3 {
        color: $color-green-blue;
      }

      a {
        margin: 0 !important;
      }
    }

  }

  padding-top: 40px;

  @include media("<=desktop") {
    padding: 60px 35px 30px 35px;
  };
}