@import "../../../ds/variables";
@import "../../../ds/media";

$area-color: (
  "conexao-de-ideias": linear-gradient(105deg, #77a595 0%, #217362 100%),
  "deep-dive": linear-gradient(105deg, #fd9727 0%, #cc4e0c 100%),
  "desafio-clinico": linear-gradient(105deg, #60e9ff 0%, #03a7c2 100%),
  "descomplicando-ciencia": linear-gradient(105deg, #a489ee 0%, #624aa6 100%),
  "einstein-practice": linear-gradient(105deg, #0287fa 0%, #0262b7 100%),
  "fast-challenge": linear-gradient(105deg, #f090f7 0%, #933c9a 100%),
  "opiniao-do-especialista": linear-gradient(105deg, #b1f864 0%, #64ab17 100%),
  "pilula-de-conhecimento": linear-gradient(105deg, #e389a8 0%, #d23b70 100%),
  "reuniao-cientifica": linear-gradient(105deg, #f9886c 0%, #f04b22 100%),
  "default": linear-gradient(105deg, #107b95 0%, #1bb28e 100%),
);

[data-mix-blend-subarea-interesse] {
  background: url("../../../assets/images/Fundo-pb.png") 0 0 no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  [data-layer1] {
    background-image: map-get($area-color, "default");
    height: 100%;
    opacity: 0.8;
    width: 100%;

    @each $name, $value in $area-color {
      &.#{$name} {
        background-image: $value;
      }
    }
  }
}

.oe-search-result-filters {
  background-size: cover;
  height: 100%;
  width: 100%;
  color: $color-gray;
  position: relative;
  display: flex;
  padding: 0px;

  @include media("<=desktop") {
    padding: 25px;
  }

  @each $name, $value in $area-color {
    &.#{$name} {
      h2 {
        padding-bottom: 0.2em !important;
      }
    }
  }

  .oe-form-filter {
    strong {
      color: $color-default;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .oe-search-form {
    h2 {
      margin-top: 36px !important;
      margin-bottom: 0px;
      font-size: 2.1875rem;

      @include media("<=desktop") {
        margin: 0px !important;
        font-size: 1.25rem !important;
      }
    }

    p {
      text-transform: uppercase;
      font-weight: 600;
      padding: 40px 0 5px 0;
    }

    h3 {
      font-family: $font-family-monserrat;
      font-style: normal;
      font-weight: $weight-bold;
      font-size: 22px;
      line-height: 27px;
      color: $color-white;
      margin-bottom: 39px;

      @include media("<=desktop") {
        margin: 0px !important;
        font-size: 1rem !important;
      }
    }

    form {
      padding: 0 !important;
      margin: 0 !important;
    }

    strong {
      font-size: 14px;
      font-weight: 700;
    }

    .oe-search-form-wrapper {
      display: flex;
      flex-wrap: nowrap;

      @include media("<=phone") {
        flex-wrap: wrap;
      }
      justify-content: space-between;

      > div:not(:last-child) {
        width: 47%;
        min-width: 47%;
      }

      > div {
        margin-bottom: 1.2rem;
        padding: 0;

        &.oe-search-form--filter__w-100 {
          width: 100%;
          margin-right: 0;
        }

        &.oe-search-form--filter__w-30 {
          width: 30%;
          margin-right: 0;

          @include media("<=phone") {
            width: 100%;
          }
        }
      }

      // Input checkbox filter
      label {
        margin-bottom: 21px;
        font-size: 1rem;
        font: normal normal normal 12px/14px Open Sans;
        color: $color-default;
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #707070;

        input[type="checkbox"] {
          color: $color-default;
          background: transparent;
          border: 1px solid $color-default;
          width: 1.3em;
          height: 1.3em;
          vertical-align: bottom;
          color-adjust: exact;
          appearance: none;

          &:checked {
            display: inline-block;
            color: $color-default;
            background-color: #107b95 !important;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
          }
        }
      }

      &:last-child {
        width: 100% !important;
      }
    }

    @include media(">=desktop") {
      margin: 0 auto;
      width: 1246px;

      h2 {
        display: block;
      }

      p {
        padding: initial;
      }

      .oe-search-form-wrapper {
        justify-content: flex-start;
        margin: 0;

        > div:not(:last-child) {
          min-width: 200px;
          width: auto;
        }

        > div {
          margin-right: 1rem;
        }
      }
    }
  }
}
