@import "../../../ds/variables";

.modal-feedback {
  opacity: 0;
  position: fixed;
  background: $color-white;
  z-index: 1103;
  width: 500px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -150px;
  border-radius: 8px;
  padding: 39px 55px;

  [data-modal-close] {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  [data-modal-icon] {
    margin-bottom: 12px;
  }

  [data-modal-title] {
    font-size: $font-med;
    font-weight: $weight-bold;
    color: $color-primary;
    margin-bottom: 0.9375em;
  }

  [data-modal-description] {
    font-size: $font-normal;
    font-weight: $weight-normal;
    color: $color-default;
    line-height: 1.3;
    margin-bottom: 0.9375em;
  }

  .flex {
    width: 100%;
  }

  &__close {
    cursor: pointer;
  }

  .button-primary {
    padding: 11px 23px;
    font-size: $font-sm;
    font-weight: $weight-semibold;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}
