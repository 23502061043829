@import "../../../ds/variables";
@import "../../../ds/media";

$default-section-padding-side: calc((100% - 1272px) / 2);

[data-modal-lgdp] {
  position: fixed;
  color: #ffffff;
  bottom: 32px;
  width: 1272px;
  border-radius: 17px;
  z-index: 99;
  padding: 25px 82px;
  background: rgba(65, 65, 65, .8);
  margin-left: $default-section-padding-side;
  margin-right: $default-section-padding-side;


  @include media("<=desktop") {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    padding: 25px;
  }

  @include media("<=phone") {
    width: 332px;
    left: 50%;
    margin-left: -166px;
    padding: 25px;
  }

  [data-text-container] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media("<=desktop") {
      flex-direction: column;
    }

    & > p {
      margin: 0px;
      font-size: 0.875rem !important;
      font-family: $font-family-monserrat;
      width: 836px;
      line-height: 1.5;

      @include media("<=desktop") {
        width: 100%;
        margin-bottom: 16px;
        text-align: center;
      }

      & a{
        color: #fff;
        text-decoration: underline;
      }
    }

    .button-primary {
      border-radius: 5px;
      width: 214px;
      height: 46px;
    }
  }
}