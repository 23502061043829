@import '../../ds/variables';
$default-section-padding: calc((100% - 1246px) / 2);

.new-user {
    padding: 27px 52px 32px 52px;
}

.container-fluid {
    padding-left: $default-section-padding;
    padding-right: $default-section-padding;
    padding-bottom: 63px;
}