@import "../../../ds/variables";
@import "../../../ds/media";

[data-header-authors] {
  @include media("<=desktop") {
    width: 100%;
    flex-wrap: wrap;
  }

  [data-header-section-sub-title] {
    font-size: 1.875rem !important;
    font-weight: 600;
    margin-bottom: 15px;

    @include media("<=desktop") {
      width: 100%;
      flex-wrap: wrap;
      font-size: 1rem !important;
      margin-bottom: 1.5rem !important;
    }
  }

  [data-authors-filters] {
    .field-control {
      height: 31px;
      width: 266px;
      margin-left: 52px;

      @include media("<=desktop") {
        margin-left: 0px;
        width: 100%;
      }
    }

    & > div:nth-child(1) {
      @include media("<=desktop") {
        width: 50%;
        padding-right: 5px;
      }
    }
    & > div:nth-child(2) {
      @include media("<=desktop") {
        width: 50%;
        padding-left: 5px;
      }
    }
  }
}

[data-authors-container] {
  // padding: 47px 32px 60px 32px;
  @include media(">=desktop") {
    width: 1246px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }

  .ade-pagination {
    margin-bottom: 42px;
  }

  [data-card] {
    width: 100% !important;
    box-shadow: 0 3px 6px #00000029;
    height: auto;
    margin-bottom: 32px;

    @include media(">=desktop") {
      padding: 0px;
      height: 258px;
      margin-bottom: 52px;
    }

    [data-author-photo] {
      & > div {
        background-color: #ebebeb;
      }
    }

    [data-author-info] {
      [data-author-cargo] {
        margin-bottom: 16px;

        [data-author-contact] {
          display: flex;
          flex-direction: row;
          align-items: center;

          @include media("<=phone") {
            flex-direction: column;
            flex-wrap: wrap;
          }

          [data-author-item] {
            flex-direction: row;
            align-items: center;

            &:last-child {
              margin-bottom: 0px !important;
            }

            &:not(:last-child) {
              margin-right: 20px;

              @include media(">=phone") {
                padding-right: 20px;
                border-right: solid 2px $color-primary;
              }
            }

            > span {
              &:first-child {
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }
}
