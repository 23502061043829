@import "../../../ds/media";
@import "../../../ds/variables";

[data-header-page-breadcrumb] {
  color: $color-white;
  padding: 0 auto;
  background: url("../../../assets/images/bg-home-destak.png") 0 0 no-repeat;
  background-size: cover;

  @include media("<=tablet") {
    margin: 0px 0px -14px 0px;
  }

  @include media(">tablet") {
    margin: 0px 0px 0px 0px;
  }

  @include media("<=tablet") {
    height: auto;
  }

  [data-body-page-breadcrumb] {
    margin: 0 auto;

    @include media(">tablet") {
      // width: 1246px;
    }

    @include media("<=tablet") {
      padding: 25px 0;

      [data-breadcrumb] {
        padding: 11px 0px 15px 0px;
      }
    }

    [data-limpa-filtro-page-breadcrumb] {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      text-decoration-line: underline;
      color: $color-white;

      cursor: pointer;
      margin-bottom: 9px;
    }
  }
}
