@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-modal-user-succeed] {
  padding: 3rem 2.5rem;
}

.modal-user-succeed {
  opacity: 0;
  position: absolute;
  background: $color-white;
  z-index: 1103;
  width: 500px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -150px;
  border-radius: 8px;
  padding: 13px;

  @include media("<=phone") {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0;

    img {
      @include media("<=phone") {
        margin: 30% 0 5% 0 !important;
      }
    }
  }

  .flex {
    width: 100%;
  }

  .profile {
    margin-top: 17px;
    margin-bottom: 16px;
  }

  &__title {
    font-size: $font-med;
    font-weight: $weight-bold;
    color: $color-primary;
    margin-bottom: 0.5rem;

    @include media("<=phone") {
      margin-bottom: 1rem;
    }
  }

  &__close {
    cursor: pointer;
  }

  &__description {
    font-size: $font-normal;
    font-weight: $weight-normal;
    color: $color-default;
    padding: 0 12px;
    line-height: 1.5;
    margin-bottom: 0.5rem;

    @include media("<=phone") {
      margin-bottom: 1rem;
    }
  }

  .button-primary {
    padding: 11px 23px;
    font-size: $font-sm;
    font-weight: $weight-semibold;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}
