@import "../../../../../ds/variables";
@import "../../../../../ds/media";


.espaco {
  margin-bottom: 80px;
}

[data-audio-preview-mobile] {
  display: flex;
  width: 100%;
  flex-direction: column;

  .tag {
    width: max-content !important;
  }

  [data-audio-preview-mobile] {
    width: 100%;
    height: 200px;
    background-size: cover;
    padding: 14px 32px 0 32px;
    flex-direction: column;
    justify-content: space-between;
  }

  [data-audio-status-mobile] {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
  }

  [data-audio-info-mobile] {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 10px 10px 0 0;
    margin-top: -15px;
    background-color: $color-white;
    z-index: 999;

    margin-right: 0px;
    width: 100%;

    [data-player-controls] {
      background: #0A589B;
      padding: 1rem 0px 1rem 0px;
    }

    h1 {
      color: $color-green-blue;
      font-size: 30px;
      font-weight: $weight-bold;
      width: 100%;
      margin-bottom: 15px;

      font-size: 16px;

      >span {
        margin-right: 20px;
      }
    }

    [data-audio-recommended-mobile] {
      width: 100%;
      line-height: 1.3;
      margin-top: 26px;

      .tags {
        >span {
          background-color: #9A0B83;
          color: #FDFEFF;
        }
      }

      margin-bottom: 0px;
    }

    [data-audio-info-time] {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 26px;

      [data-audio-info-icon] {
        padding: 1px 2px 1px 2px;
        border: 2px solid #107B95;
        border-radius: 8px;
        color: #107B95;
        font-weight: 600;
        font-size: 7px;
      }

      [data-audio-info-icon-img] {
        margin: 0px;
        width: 16px;
        height: 16px;
      }

      [data-audio-info-type] {
        margin-left: 5px;
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #707070;
      }

      [data-audio-info-icon-date] {
        margin: 0px 0px 0px 10px;
        width: 16px;
        height: 16px;
      }
    }

    [data-audio-info-palavra-chave] {
      margin-top: 25px;

      [data-audio-info-palavra-chave-title] {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #707070;
      }

      [data-audio-info-tags] {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .tags {
          >span {
            background-color: #E7F2F4;
            color: #107B95;
            font-weight: 700;
            font-size: 9px;
            line-height: 11px;
          }
        }

        [data-audio-info-ver-mais] {
          cursor: pointer;

          [data-audio-info-icone-ver-mais] {
            margin: 0px;
            width: 24px;
            height: 24px;
          }

          [data-audio-info-icone-ver-mais-label] {
            font-family: $font-family-monserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #0A589B;
            margin-left: 12px;
          }
        }
      }
    }

    [data-audio-content-mobile] {
      width: 100%;
      height: auto;
      margin-right: 30px;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-color: #9b9b9b #f4f4f4;
      scrollbar-width: thin;
      margin-top: 30px;

      * {
        line-height: 1.5;
      }

      [data-audio-content-mobile-recomendado] {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #707070;
      }

      p {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #707070;

        margin-top: 12px;
      }

      @include media("<=desktop") {
        height: auto;
        width: 100%;
      }

      img {
        @include media("<=desktop") {
          width: 100%;
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        background: #f4f4f4;
        border: solid 2px #e4e4e4;
        border-radius: 0px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 0px;
        box-shadow: inset 0 0 6px #9b9b9b;
        -webkit-box-shadow: inset 0 0 6px #f4f4f4;
      }

      &::-webkit-scrollbar-thumb {
        margin: 2px;
        border-radius: 0px;
        position: absolute;
        background: #107b95;
        border: 2px solid #e4e4e4;
      }
    }

    .button {
      margin-top: 2rem;
      background-color: $color-green-blue;

      @include media("<=desktop") {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
      }
    }
  }
}


@include media("<=phone") {
  .deslogado {
    bottom: 55px !important;
  }
}
@include media("<=tablet") {
  .deslogado {
    bottom: 55px !important;
  }
}
