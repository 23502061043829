@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-about-authors] {
  // padding: $default-section-padding;

  @include media("<=desktop") {
  }

  @include media(">=desktop") {
    gap: 25px;
    padding: 15px;
  }

  @include media("<=phone") {
    border-radius: 14px 14px 0 0;
    // margin-top: ;
  }

  > div {
    width: 1246px;
    margin: 0 auto;

    @include media("<=desktop") {
      width: 100%;
      margin: auto;
    }

    > div {
      display: flex;
      justify-content: space-between;
    }
  }

  h2 {
    color: #107b95;
    display: inline-block;
    font-size: 18px !important;
    margin-bottom: 3rem;
    padding-top: 1rem;
    font-weight: 600;
    text-shadow: 0 0 1px #107b95;
    text-transform: none;
  }

  [data-authors-content] {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 30px;

    @include media(">=phone") {
      padding: 0px;
      grid-template-columns: repeat(2, 1fr);
    }

    [data-author-box] {
      margin-bottom: 0px !important;
      padding-left: 0px;

      @include media(">=desktop") {
        width: 100%;
      }

      [data-author-photo] {
        & > div {
          background-color: #fff;
        }
      }

      [data-author-info] {
        @include media(">=desktop") {
          margin-right: 25px;
        }

        [data-author-contact] {
          [data-author-item] {
            &:not(:last-child) {
              margin-bottom: 5px;
            }

            &:last-child {
              margin-bottom: 1rem;
            }

            > span {
              &:first-child {
                width: 22px;
                text-align: center;
                margin-right: 8px;
              }
            }
          }
        }
      }

      [data-author-buttons] {
        @include media(">=desktop") {
          button {
            width: 171px;
          }
        }
      }
    }
  }
}
