@import "../../../ds/variables";
@import "../../../ds/media";

[data-modal-error] {
  width: 664px;
  padding: 0px;

  @include media("<=desktop") {
    width: 100%;
    height: 100%;
    padding: 2rem;
  }

  h1 {
    font-size: 1.2rem;
    color: $color-primary;
    justify-content: center;
    display: flex;
    margin: 60px 40px 11px 40px;
    font-weight: bold;

    @include media("<=desktop") {
      text-align: center;
      margin: 100px 0px 30px 0px;
    }
  }

  [data-description] {
    font-size: 1rem;
    color: $color-default;
    justify-content: center;
    display: flex;
    margin: 0px 40px 0.5rem 40px;

    @include media("<=desktop") {
      margin: 0px 0px .5rem 0px;
      text-align: center;
    }
  }

  [data-more-details-link] {
    display: flex;
    justify-content: center;
    text-decoration: underline;

    transition: color 200ms ease-in-out;

    color: $color-primary;
    font-size: $font-sm;
    font-weight: normal;
    text-decoration: underline;

    cursor: pointer;

    &:hover {
      color: $color-light-blue;
    }

    &:focus {
      color: $color-light-blue;
    }
  }

  [data-more-details] {
    display: flex;
    justify-content: center;
    margin: 0px 40px 1rem 40px;
    padding: 2rem;
    background-color: #f4f4f4;
    color: $color-default;
    border-radius: 1rem;
    text-align: center;

    @include media("<=desktop") {
      margin: 30px 0px;
    }
  }

  [data-buttons] {
    display: flex;
    justify-content: center;

    @include media("<=desktop") {
      flex-direction: column;
      width: 100%;
      align-items: center;
      flex-direction: column-reverse;
    }

    & > button {
      width: 161px;
      padding: 0.5rem 0px;

      @include media("<=desktop") {
        width: 100%;
      }

      &:nth-child(2) {
        margin-left: 1rem;
        @include media("<=desktop") {
          margin-left: 0px;
          margin-bottom: .5rem;
        }
      }
    }
  }
}
