@import '../../../../ds/variables';
@import "../../../../ds/media";

.menu-mobile {
    opacity: 0;
    position: fixed;
    // position: absolute;
    background: $color-primary;
    z-index: 6000;

    @include media("<=desktop") {
        padding-top: 2rem;
    }

    &__toggle {
        cursor: pointer;
        > span {
          display: block;
          height: 2px;
          width: 26px;
          background-color: $color-default;
          transition: all 300ms ease-in-out;
          transform-origin: 3px 1px;
    
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    &__menu {
        top: 3rem;
        width:100%;
        height: 100%;
        overflow-y: scroll;
    
        .link-secondary {
            color: $color-white;
            padding: 0.875rem;
            display: block;
            text-align: center;
            font-family: $font-family-monserrat;
            font-style: normal;
            font-weight: $weight-semibold;
            font-size: $font-med2;
            line-height: 240.9%;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            img {
                position: relative;
                top: -4px;
                left: -1px;
            
            }
        }
    }
      

    &.open {
        .menu-mobile__toggle {
            > span:first-child {
                transform: rotate(45deg);
            }

            > span:nth-child(2) {
                opacity: 0;
            }

            > span:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}

.show {
    display: block;
}

.hide {
    display: none;
}