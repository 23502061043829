@import "../../../../ds/variables";
@import "../../../../ds/media";
[data-oe-footer] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  backdrop-filter: blur(0.7px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  &__public-date {
    font-size: $font-normal;
    font-weight: $weight-normal;
    white-space: nowrap;
  }
  & > div:first-child {
    & > div:first-child {
      border-right: solid 2px $color-white;
      padding-right: 9px;
    }
  }
  [data-oe-footer-timer] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    [data-metadata-oe-separator] {
      height: 13px;
      width: 2px;
      margin-left: 10px;
      margin-right: 10px;
      @each $name, $value in $colors {
        &[data-metadata-oe-separator*="#{$name}"] {
          background: $value;
        }
      }
    }
    [data-certificado-card] {
      img {
        height: 17px;
      }
      margin-right: 10px;
    }
    [data-visualized] {
      font-size: 14px;
    }
  }
  [data-icons-shared-remember] {
    display: flex;
    flex-direction: row;
    > div {
      margin-right: 10px;
    }
    > .remember-container {
      margin-right: 0px;
    }
    .remember-container img {
      height: 20px;
    }
  }
}