@import '../../ds/variables';
@import '../../ds/media';

.oe-search-page {
  .oe-search-result, .oe-search-error {
    padding: 0 30px;
    @include media('>=desktop') {
      width: 1246px;
      margin: 0 auto;
      padding: 0;
    }
  }

  .oe-search-error {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
 }
