@import "../../ds/variables";
@import "../../ds/media";

$area-color: (
  "conexao-de-ideias": linear-gradient(105deg, #77a595 0%, #217362 100%),
  "deep-dive": linear-gradient(105deg, #fd9727 0%, #cc4e0c 100%),
  "desafio-clinico": linear-gradient(105deg, #60e9ff 0%, #03a7c2 100%),
  "descomplicando-ciencia": linear-gradient(105deg, #a489ee 0%, #624aa6 100%),
  "einstein-practice": linear-gradient(105deg, #0287fa 0%, #0262b7 100%),
  "fast-challenge": linear-gradient(105deg, #f090f7 0%, #933c9a 100%),
  "opiniao-do-especialista": linear-gradient(105deg, #b1f864 0%, #64ab17 100%),
  "pilula-de-conhecimento": linear-gradient(105deg, #e389a8 0%, #d23b70 100%),
  "reuniao-cientifica": linear-gradient(105deg, #f9886c 0%, #f04b22 100%),
  "default": linear-gradient(105deg, #107b95 0%, #1bb28e 100%),
);

[data-header-page-breadcrumb] {
  margin: 0px 0px 11px 0px !important;
}

.oe-search-result-filters {
  background-size: cover;
  height: 100%;
  width: 100%;
  color: $color-white;
  position: relative;

  display: flex;
  padding: 0px;

  @include media("<=desktop") {
    padding: 25px;
  }

  @each $name, $value in $area-color {
    &.#{$name} {
      h2 {
        padding-bottom: 0.2em !important;
      }
    }
  }
}

[data-filter-area-conhecimento] {
  label{
    font-size: .75rem !important;
    text-transform: uppercase;
    font-weight: 600;
  }
}

[data-empty] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}

[data-count-result] {
  margin: 0 auto;

  span {
    color: #4A4A4A;
    font-size: 14px;
    font-weight: 700;
    @include media("<desktop") {
      font-size: 12px;
    }
  }

  @include media(">=desktop") {
    width: 1246px 
  }
  @include media("<desktop") {
    margin: 0 25px 
  }
}