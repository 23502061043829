@import "../../ds/variables";
@import "../../ds/media";

$default-section-padding: 50px calc((100% - 1246px) / 2); // 63px 32px;

[data-password-reset] {
  padding: $default-section-padding;

  @include media("<=phone") {
    height: 100%;
  }

  [data-card] {
    padding: 2em;

    @include media("<=phone") {
      height: 100%;
      box-shadow: none;
      border-radius: 0px;
    }

    h3 {
      text-transform: none;
      color: $color-primary;
      margin-bottom: 24px;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      [data-row] {
        display: flex;
        width: 100%;
        flex-direction: row;
        margin: 0 -0.5rem;

        @include media("<=phone") {
          flex-wrap: wrap;
          margin: 0px;
        }

        [data-col-1],
        [data-col-2] {
          display: flex;
          flex-direction: row;
          margin: 0 0.5rem;

          @include media("<=phone") {
            margin: 0px;
          }
        }

        [data-col-1] {
          width: 100%;
          justify-content: flex-end;
        }

        [data-col-2] {
          width: 50%;

          @include media("<=phone") {
            width: 100%;
          }

          [data-icone] {
            cursor: pointer;
          }

          .MuiFormControl-root {
            display: flex;
            width: 100%;
            margin-bottom: 36px;

            label {
              font-weight: bold;
              font-size: 0.75rem;
              color: $color-default;
              font-family: $font-family-opensans;
            }

            .MuiInputBase-root {
              width: 100%;

              input {
                font-size: 0.75rem;
                font-family: $font-family-opensans;
              }
            }
          }
        }
      }

      > button {
        grid-column: 2;
        justify-self: end;
      }
    }
  }
}
