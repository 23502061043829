@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-sub-area] {
  columns: 1 !important;
  -webkit-columns: 1 !important;
  -moz-columns: 1 !important;
  margin-top: 0px !important;
  display: none;
  opacity: 1 !important;
}

.sub-area-selected {
  display: block;
}