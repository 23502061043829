@import "../../../ds/media";

[data-banner-hide-patrocinado] {
  width: 1246px;
  height: 205px;

  .title-patrocinado {
    display: none;
  }
}

[data-banner-show-patrocinado] {
  width: 100% !important;
  min-height: 263px;
  height: auto;
  background-color: #f1f1f1;
  margin-bottom: 0px !important;
  padding-top: 26px;

  a img {
    width: 93% !important;
    height: 93% !important;
    border-radius: 15px !important;
  }

  .banner-swiper {
    margin: 3px 27px 0 !important;
  }

  .title-patrocinado {
    color: #707070;
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }
}

[data-banner] {
  margin: 0 auto;

  @include media("<=phone") {
    width: 100%;
    height: auto;
  }
  a {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  [data-banner-desktop] {
    display: block;

    @include media("<=desktop") {
      display: none;
    }
  }

  [data-banner-mobile] {
    display: none;

    @include media("<=desktop") {
      display: flex;
      justify-content: center;
    }
  }
}
