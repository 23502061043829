[data-date-view] {
  align-items: center;
  display: flex;
  font-family: "Open Sans";
  justify-content: center;

  & > .date-icon {
    margin-right: 12px;
    height: 15px;
  }

  > div {
    margin-left: 0 !important;
    display: flex;
    > span {
      display: inline-block !important;
      margin-left: 0 !important;
      white-space: nowrap;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;

      &.space {
        padding: 0 0.2em;
      }
    }
  }

  &[data-animate="true"] {
    > div > span {
      animation: fadeInDown 433ms ease;
      animation-fill-mode: both;

      @for $i from 1 to 10 {
        $delay: $i * 133;

        &:nth-child(#{$i}) {
          animation-delay: #{$delay}ms;
        }
      }
    }
  }
}
