@import "../../../../ds/variables";

[data-link-enviado-modal],
[data-forgot-password-modal] {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 2em 26px;
  width: 100% !important;
  z-index: -1;
  width: 530px;

  h3 {
    color: $color-primary;
    margin-bottom: 10px;
  }

  [data-descricao] {
    width: 382px;
    padding: 0px 45px;
    font-size: 0.875rem;
    text-align: center;
  }

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .MuiFormControl-root {
      display: flex;
      width: 100%;
      margin-bottom: 36px;

      label {
        font-weight: bold;
        font-size: 0.75rem;
        color: $color-default;
        font-family: $font-family-opensans;
      }

      .MuiInputBase-root {
        width: 100%;

        input {
          font-size: 0.75rem;
          font-family: $font-family-opensans;
        }
      }
    }
  }
}

[data-link-enviado-modal] {
  padding: 63px 74px; 

  p {
    padding: 0px 35px;
    text-align: center;
    margin-bottom: 29px;
  }
}