@import "../../../ds/variables";
@import "../../../ds/media";

.modal-pre-register-user {
  opacity: 0;
  position: absolute;
  background: $color-white;
  z-index: 1101;
  justify-content: center;
  flex-direction: column;

  &.show {
    display: flex;
  }

  @include media("<=desktop") {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    margin-left: 0;
    border-radius: 0px;
    padding: 13px 26px;
  }

  @include media(">=desktop") {
    width: 660px;
    left: 50%;
    top: 50%;
    margin-left: -280px;
    margin-top: -190px;
    border-radius: 8px;
    padding: 42px 13px;
  }

  .profile {
    margin: 0px 0px 8px 0px;
  }

  &__title {
    font-size: $font-med;
    font-weight: $weight-bold;
    color: $color-primary;
    padding: 0 26px 1rem 26px;
    line-height: 1.4;

    @include media("<=desktop") {
      font-size: $font-med2;
      padding: 0 0 1rem;
    }
  }

  &__close {
    cursor: pointer;
  }

  &__description {
    font-size: $font-normal;
    font-weight: $weight-normal;
    color: $color-default;
    margin-left: -30px;

    @include media("<=desktop") {
      margin-left: 0;
    }
  }

  &__icon {
    padding: 3rem 0 1rem 0;

    @include media(">=desktop") {
      padding: 0.5rem 0 1rem 0;
    }
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  ul li {
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
    text-align: left;

    & + li {
      margin-top: 5px;
    }
  }

  ul li img {
    margin-top: 3px;
    margin-right: 18px;
  }

  .button-primary {
    margin-right: 20px;

    @include media("<=desktop") {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  .wrapper-button-secondary-blue {
    @include media("<=desktop") {
      width: 100%;
      display: inline-block;
    }

    .button-secondary-blue {
      position: relative;

      &:after {
        content: "*Acesso restrito";
        position: absolute;
        bottom: -23px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 0.65rem;
        font-style: italic;

        @include media("<=desktop") {
          left: auto;
        }
      }
    }
  }

  [data-buttons] {
    @include media("<=desktop") {
      flex-wrap: wrap;
    }

    * > {
      display: block;
    }

    .button-primary,
    .button-secondary-blue {
      @include media("<=desktop") {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      height: 35px;
      padding: 8px 0px;
      font-size: $font-sm;
      font-weight: $weight-bold;
      width: 166px;
    }
  }

  .link-redirect-register {
    cursor: pointer;
    margin: 32px 0px 28px;
    color: $color-default;
    font-weight: $weight-bold;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}
