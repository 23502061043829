@import "../../../ds/variables";
@import "../../../ds/media";

[data-breadcrumb] {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 30px 0px 15px 0px;
  justify-content: left;

  @include media(">=desktop") {
    padding: 25px 0px 9px 0px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  [data-breadcrumb-item] {
    list-style: none !important;
    margin: 0px;
    padding: 0px;
    font-family: $font-family-monserrat;
    font-weight: $weight-normal;

    // Last active child
    + [data-breadcrumb-item] {
      &::before {
        float: left;
        padding: 0px 0.5rem;
        content: ">";
        font-weight: lighter;
        // color: white;
      }

      &.breadcrumb-active {
        // font-weight: bold;
      }
    }

    a {
      color: $color-white;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
