@import "../../../ds/variables";
@import "../../../ds/media";


.quizz {
  [data-breadcrumb] [data-breadcrumb-item] {
    color: #111316 !important;
    a { 
      color: #111316 !important;
    }
  }

  [data-breadcrumb] [data-breadcrumb-item] + [data-breadcrumb-item]::before {
    color: #111316 !important;
  }
}

[data-oe-quiz] {
  // padding: $default-section-padding;

  @include media("<=desktop") {
    background-image: none !important;
    background-size: none !important;
    width: 100%;
    padding: 20px 25px;
  }

  header {
    h2 {
      margin-bottom: 15px;
      margin-top: 15px;

      @include media("<=desktop") {
        flex-wrap: wrap;

        [data-oe-back-button] {
          display: none !important;
        }
      }

      & > span {
        font-size: 30px;
        @include media("<=desktop") {
          font-size: 14px;
          width: 100%;
        }
      }

      [data-oe-back-button] {
        filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(150deg)
          saturate(600%) contrast(0.8);
        margin-right: 1em;
        padding-left: 0;
      }
    }

    [data-metadata] {
      display: flex;
      width: 100%;
      margin-bottom: 15px;

      [data-time-view] {
        display: flex;
        align-items: center;
        margin-right: 16px;
      }

      [data-date-view] {
        display: flex;
        align-items: center;
        font-family: $font-family-opensans;

        > div {
          padding-bottom: 0px;
        }
      }
    }

    [data-recommended] {
      margin-bottom: 37px;
      line-height: 1.3;
      @include media("<=desktop") {
        padding: 25px 25px 0px 25px;
      }
    }

    p {
      margin-top: 0.4em;
    }

    [data-info] {
      font-family: "Open Sans";
    }

    > div {
      span {
        align-items: center;
        display: flex;
      }

      span {
        margin-left: 2em;

        img {
          margin-right: 0.3em;
        }
      }

      strong {
        font-weight: 600;
        text-shadow: 0 0 1px #5c6268;

        &:not(:first-of-type) {
          margin-left: 0.4em;
        }
      }
    }

    h2 {
      align-items: center;
      color: #107b95;
      display: flex;
      text-shadow: 0 0 1px #107b95;

      .tag {
        margin-left: 2em;
      }
    }
  }

  // section
  [data-content] {
    display: flex;

    @include media("<=desktop") {
      flex-wrap: wrap;
    }

    * {
      font-family: "Open Sans";
    }

    [data-content-media] {
      width: 504px;
    }

    [data-content-question] {
      width: 742px;
      padding-left: 25px;
      @include media("<=desktop") {
        width: 100%;
        padding-left: 0px;
      }

      [data-alternativa-correta] {
        display: flex;
        flex-direction: column;
        @include media("<=desktop") {
          width: 100%;
          padding-left: 0px;
        }

        * {
          @include media("<=desktop") {
            width: 100%;
            padding-left: 0px;
          }
        }
      }

      & > .button-primary {
        border: 1px solid #107b95;
        background: #107b95;

        @include media("<=desktop") {
          display: block;
          width: 100%;
        }
      }
    }

    [data-opcoes] {
      display: flex;
      flex-direction: column;
      line-height: 1.3;
      width: 100%;

      .opcoes {
        display: flex;
        align-items: center;

        [data-line-height] {
          @include media("<=desktop") {
            width: 18.4rem;
          }
        }
      }

      @include media(">desktop") {
        .opcoes {
          max-width: 70%;
        }
      }

      @include media("<=desktop") {
        margin: 21px 0px 21px 0px;

        .opcoes {
          input {
            font: inherit;
            color: currentColor;
            width: 1.20em;
            height: 1.20em;
            border: 0.20em solid currentColor;
            border-radius: 50%;
            transform: translateY(-0.075em);
          }
        }
      }

      [data-porcentage-bar] {
        @include media("<=desktop") {
          margin-top: 36px;
        }
      }

      > label {
        align-items: center;
        display: flex;
        justify-content: space-between;

        [data-answer-count] {
          align-items: center;
          box-sizing: content-box !important;
          display: flex;
          position: absolute;
          right: 0;

          @include media("<=desktop") {
            position: relative;
            width: 100%;
            top: 0px;
          }

          > div {
            align-items: center;
            border: 1px solid #107b95;
            border-radius: 100px;
            display: flex;
            height: 16px;
            position: relative;
            width: 210px;

            @include media("<=desktop") {
              width: 100%;
            }

            &:after {
              background-color: #107b95;
              border-radius: 100px;
              content: " ";
              height: 8px;
              left: 4px;
              position: absolute;
              transition: width 433ms ease;
              width: 0;
            }

            @for $i from 0 to 101 {
              &.percentage#{$i} {
                &:after {
                  @if $i < 100 {
                    width: calc(#{$i}% - 0px);
                  } @else {
                    width: calc(100% - 8px);
                  }
                }
              }
            }
          }

          [data-porcentage-title] {
            position: absolute;
            top: -3em;

            @include media("<=desktop") {
              position: relative;
              width: 100%;
              top: 0px;
            }
          }

          > span {
            font-size: 14px;
            margin-left: 0.2em;
          }

          > span:last-child {
            width: 30px;
            text-align: right;
          }
        }
      }
    }

    [data-feedback] {
      margin-top: 43px;
      width: 100%;

      &[data-feedback-title] {
        font-size: 14px !important;
        font-weight: bold;
      }

      * {
        width: 100%;
      }
    }

    > div label {
      margin-top: 1em;
    }

    > div h4 {
      font-weight: $weight-semibold;

      @include media("<=desktop") {
        margin-top: 3rem;
      }
    }

    > img {
      margin-right: 2em;
    }

    > div button {
      align-self: flex-start;
      justify-self: flex-end;
      margin-top: 3em;
    }

    label input + span {
      cursor: pointer;
      margin-left: 1em;
    }
  }

  [data-opcoes] {
    label {
      animation: fadeInDown 433ms ease;
      animation-fill-mode: both;

      @for $i from 1 to 100 {
        $delay: $i * 133;

        &:nth-child(#{$i}) {
          animation-delay: #{$delay}ms;
        }
      }
    }
  }

  .player-video {
    display: flex;
    justify-content: start;
  }

  .demaisOpcoes {
    color: transparent;
  }
}
