@import "../../../../../ds/media";

[data-player-controls] {
  color: white;
  display: grid;
  grid-template-columns: 55px 55px 55px 55px 3fr 55px 55px;
  width: 100%;

  @include media("<=desktop") {
    background: rgb(18,18,18);
    padding-bottom: 1rem;
  }

  button {
    color: white !important;
    display: block;
    border-radius: 0;
    max-width: 55px;
    max-height: 55px;
    outline: 0;

    svg {
      width: 30px;
      height: 30px;
      max-width: 35px;
      max-height: 35px;
    }

    time {
      min-width: 40px;
      display: block;
    }
  }
}
