@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-footer-mobile] {
  margin-bottom: 3.25rem;
  display: flex;
  flex-direction: column;

  [data-atendimento] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 25px 0;
    border-top: solid 1px #dedede;

    & h4 {
      color: $color-primary;
      font-size: 1rem;
      font-weight: $weight-bold;
      text-align: center;
      margin-bottom: 1rem;
    }

    [data-links] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 0px 2rem;

      & a {
        height: 100%;
        color: $color-default;
        font-weight: $weight-bold;
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        align-items: flex-end;
        font-size: 0.875rem;
        font-family: $font-family-opensans !important;
        &:not(:last-child) {
          margin-bottom: 0.875rem;
        }
      }

      & span {
        @include media("<=phone") {
          display: flex;
          text-align: center;
          width: 100% !important;
          justify-content: center;
          margin-bottom: 16px;
        }
      }
    }
  }

  [data-social-media] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 25px 0;

    & h4 {
      color: $color-primary;
      font-size: 1rem;
      font-weight: $weight-bold;
      text-align: center;
      margin-bottom: 1rem;
    }

    [data-links] {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      & > a {
        &:not(:last-child) {
          margin-right: 0.875rem;
        }
      }
    }
  }

  [data-logo] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 25px 0px;
    border-top: solid 1px #dedede;
  }

  .MuiPaper-root {
    border-radius: 0;

    svg {
      fill: $color-white;
    }
  }

  .MuiAccordionSummary-root {
    background: $color-primary;
    color: $color-white;
    padding: 0 2rem;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordion-rounded {
    border-radius: 0px !important;
  }

  .MuiAccordionDetails-root {
    @include media("<=phone") {
      padding: 1rem 2rem;
    }
  }

  @include media(">=phone") {
    display: none;
  }

  [data-copyrigth] {
    padding: 2rem 2rem 0 2rem;
    & > p {
      font-size: 0.625rem;
      text-align: center;
      font-weight: normal;
    }
  }
}
