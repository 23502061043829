@import "../../../ds/variables";
@import "../../../ds/media";

[data-author-details],
[data-author-knowledge-area],
[data-author-multimedia-contents],
[data-author-text-contents] {
  width: 100%;
  padding-left: $default-section-padding-side;
  padding-right: $default-section-padding-side;

  [data-title] {
    color: $color-green-blue;
  }
}

[data-author-details] {
  position: absolute;
  margin-top: -35px;
  height: 258px;

  @include media("<=desktop") {
    margin: 50px 0px 50px 0px;
    position: relative;
    height: auto;
    padding-right: 25px;
    padding-left: 25px;
  }

  [data-author-box] {
    [data-author-buttons] {
      .button-secondary-blue {
        display: none;
      }
    }
    [data-author-photo] {
      & > div {
        background-color: #ebebeb;
      }
    }
    [data-author-info] {
      [data-author-cargo] {
        margin-bottom: 16px;

        [data-author-contact] {
          display: flex;
          flex-direction: row;

          @include media("<=phone") {
            flex-direction: column;
            flex-wrap: wrap;
          }

          [data-author-item] {
            flex-direction: row;

            &:not(:last-child) {
              margin-right: 20px;

              @include media(">=phone") {
                padding-right: 20px;
                border-right: solid 2px $color-primary;
              }
            }

            > span {
              &:first-child {
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }
}

[data-author-knowledge-area] {
  // margin: 273px 0px 50px 0px;

  @include media("<=desktop") {
    font-size: $font-sm;
  }

  .grid-container {
    @include media("<=phone") {
      grid-template-columns: repeat(2, 1fr) !important;
      font-size: $font-sm;
    }
  }

  h3 {
    color: $color-green-blue;
    margin-bottom: 24px;
  }

  [data-card] {
    width: 100% !important;
    height: 200px !important;
    // box-shadow: 0 3px 6px #00000029;
    padding: 0.5rem;
    text-align: center;
  }

  [data-card-content] {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      max-height: 100px;
    }

    h4 {
      font-size: $font-med2;
      font-weight: $weight-semibold;
      color: #000000;
      padding-top: 1rem;
      @include media("<=desktop") {
        font-size: $font-sm;
      }
    }
  }
}

[data-author-multimedia-contents] {
  @include media("<=desktop") {
    padding-top: 0px !important;

    .card-section {
      margin-top: 0px !important;
      &__title-bar {
        padding: 0;
        white-space: nowrap;
      }
      &__link {
        white-space: nowrap;
      }
    }

    .container {
      padding: 0;
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }

  .card-section {
    margin-bottom: 0;
  }

  @include media(">=desktop") {
    .card-section {
      margin-top: 41px;
    }
  }
}

[data-author-text-contents] {
  background: $color-light-green-blue-transparent;
  padding-top: 36px;
  padding-bottom: 74px;

  @include media("<=desktop") {
    padding-right: 25px;
    padding-left: 25px;
  }

  header {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;

    h3 {
      color: $color-primary;
    }

    a {
      color: $color-primary;
      text-transform: uppercase;
      font-size: $font-sm;
      font-weight: $weight-semibold;
      padding-right: 5px;
      svg {
        margin-top: -2px;
      }
    }
  }
}

[data-opcoes] {
  margin-top: 3%;
  .opcoes {
    padding: 1%;
  }
  .resposta-correta-class .opcoes {
    width: auto;
    color: $color-light-green;
    border-radius: 10px;
    border-color: $color-light-green;
    border: 2px solid;
  }
  .resposta-incorreta-class .opcoes {
    width: auto;
    color: $color-light-red;
  }
  .MuiSvgIcon-root {
    margin: 0px 5px 0px 5px;
  }
}
