@import "../../../ds/variables";
@import "../../../ds/media";

[data-certificado-card] {
  width: 149px;
  height: 34px;
  background: #F3F6F9;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary;

  @include media("<=phone") { 
    width: 101px;
  }

a {
  font-family: $font-family-monserrat;
  font-style: $weight-normal;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-left: 0.625rem;
  color: $color-primary;

  @include media("<=phone") { 
    display: table-caption;
    width: 66px;
    font-size: 0.425rem;
    line-height: 0.45rem;
    margin-left: 0.425rem;
  }  
}
  img {
    height: 17px;
  }
}

