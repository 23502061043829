@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-temas-oferecidos]{
    align-items: center;
    align-self: center;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    color: $color-green-blue;
    margin-top: 1rem;

    h2{
        color: $color-green-blue ;
        font-weight: $weight-bold;

        @include media("<=desktop") {
            font-size: 0.875rem;
        }

        @include media('>=desktop') {
            font-size: 1.125rem ;
        }
    }

    .card{
        display: 'flex';
        flex-direction: row ;
        width: 100%;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 2%;
        height: 150px;
        
        .card-icon{
            flex-grow: 1;
            width: 30%;
            text-align: center;
            img{max-height: 77px;}
        }
        .card-content{
            flex-grow: 2;
            width: 65%;
            justify-content: center;
            h2{
                color: $color-primary;
                margin: 0;
            }
            p{
                color: $color-primary;
                font-family: $font-family-monserrat;
                font-weight: 600;
                margin: 0;
            }
            span{
                margin-top: 0;
                color: $color-primary;
                font-family: $font-family-monserrat;
                font-size: 0.75rem;
                font-weight: 600;
            }
        }
    }

    @include media("<=desktop") {
        width: 100%;
        padding: 25px;
        grid-template-columns: repeat(4, 1fr);
      }
    
    @include media("<=tablet") {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media("<=phone") {
        grid-template-columns: repeat(1, 1fr);

        .card{
            flex-direction: column;
            justify-content: center;
            height: 220px;           
            .card-icon{
                flex-wrap: wrap;
                padding: 0;
                img{
                    max-height: 77px;
                    margin-bottom: 1rem;
                }
                
            }
            .card-content{
                flex-wrap: wrap;
                width: 100%;
                text-align: center;
                span{
                    font-style: italic;
                }
            }
        }

    }

    

}