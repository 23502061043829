@import "../../../ds/media";

// Auto generate a grid containers templates columns
@mixin make-grid-templates($max) {
  @for $i from 1 through $max {
    &.grid-template-col-#{$i} {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
  }
}

// Auto generate a grid containers gap values
@mixin make-grid-gap($max) {
  @for $i from 1 through $max {
    &.gap-#{$i} {
      gap: repeat($i, minmax(0, 1fr));
    }
  }
}

.grid-container {
  @include media(">=tablet") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
  }
  @include media(">=phone") {
    display: grid;
  }

  & > a {
    [data-card] {
      @include media("<=phone") {
        margin-bottom: 1.5625rem;
      }
    }
  }

  & > div {
    @include media("<=phone") {
      margin-bottom: 1.5625rem;
    }
  }

  // Create grid gap classes
  @include make-grid-gap(5);

  // Create grid templates up to 12 columns for large devices
  @include media(">=desktop") {
    @include make-grid-templates(12);
  }
}
