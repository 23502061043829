@import "../../../../ds/variables";
@import "../../../../ds/grid";
@import "../../../../ds/media";

[data-widget-podcast] {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;

   @include media(">=desktop") {
    .home-videos-higher-item {
      //  box-shadow: 0px 3px 6px #00000029;
      max-height: 300px;
    }
  }

  @include media("<=desktop") {
    margin-top: 40px;
  }

  [data-disabled="true"] {
    opacity: 0.35;
  }

  [data-icon-spotify] {
    position: absolute;

    @include media("<=phone") { 
      bottom: 56px;
      right: 8px;
    }

    bottom: 48px;
    right: 18px;

    &:hover {
      cursor: pointer;
    }
  }

  @include media("<=phone") {
    margin-top: 40px;
  }

  @include media(">=desktop") {
    // width: 260px;
    width: auto;
  }

  [data-stack-slider] {
    padding-left: 0.725rem;
    padding-right: 0.625rem;
    width: 100%;
    margin-bottom: 2px;

    .Mui-disabled {
      opacity: 0.35;
    }
  }

  h3 {
    margin: 0px 0px 16px 0px;
    font-family: $font-family-monserrat;
    font-style: $weight-normal;
    font-weight: $weight-semibold;
    font-size: $font-med2;
    line-height: 20px;
  }

  [data-card] {
    justify-content: space-between;
    background-color: #101010;
    height: 83px;
    // width: 400px;
    width: auto;

    @include media("<=phone") { 
      height: 92px;
    }

    @include media(">=desktop") {
      height: 83px;
      width: 400px;
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0.75rem;

      @include media("<=phone") { 
        padding: 0.75rem;
      }
      height: 100%;
      width: 100%;
      
      span {
       
        > div {
          display: flex;
          align-items: center;
          width: 100%;
  
          @include media(">=desktop") {
            justify-content: space-center;
          }
        }
  
        > div:nth-child(2) {
          & [data-progress-bar] {
            position: inherit !important;
            height: 3px;
          }
  
          & [data-tempo-duracao] {
            font-size: $font-sm;
            color: #fff;
            width: 35px;
            text-align: right;
          }
        }
      }

      [data-detalhe] {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
      }

      
      [data-play] {
        cursor: pointer;
        fill: #FDFEFF;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media("<=phone") {
          margin-right: 10px;
        }

        &[data-disabled="true"] {
          pointer-events: none;
          cursor: none;
          opacity: 0.15;
        }
      }

      [data-titulo] {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 700;
        font-size: $font-med2;
        line-height: 18px;
        color: #FDFEFF;

        @include media("<=phone") { 
          display: table-caption;
          width: 230px;
        }  

        @include media("<=phone") { 
          margin-bottom: 0;
        }

      } 

      [data-autor] {
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #FDFEFF;
        margin: 0;
      }
    }

    [data-imagem-preview] {
      position: relative;
      // @include media("<=phone") {
      //   margin-top: 13px;
      //   width: calc(100% - 26px);
      // }
    }
  }
}
