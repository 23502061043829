@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-interest-areas-inner] {
  align-items: center;
  align-self: center;
  display: grid;
  gap: 24px;

  grid-template-columns: repeat(6, 1fr);
  justify-items: center;

  @include media("<=desktop") {
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  @include media("<=tablet") {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  @include media("<=phone") {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  [data-area-content] {
    align-items: center;
    background: $color-white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 11em;
    margin: auto;
    width: 186px;
    padding: 5px 10px;
    box-shadow: 0 3px 6px #00000029;
    cursor: pointer;

    > img {
      margin-bottom: 8px;
    }

    @include media("<=tablet") {
      height: 142px;
      width: 147px;

      img,
      span {
        zoom: 0.7;
      }

      span {
        font-weight: 600;
      }
    }

    span {
      text-align: center;
      font-weight: 600;
      color: #111112;
      font-size: 15px;
    }
  }
}
