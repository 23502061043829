@import "../../../../ds/variables";
@import "../../../../ds/media";

// $default-section-padding: 63px calc((100% - 1246px) / 2); // 63px 32px;

[data-view-history] {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h4 {
    width: 100%;
    color: $color-primary;
    font-size: 18px !important;
    text-align: center;
    font-weight: $weight-bold;
  }

  [data-card] {
    flex-direction: row;
    justify-content: space-around;
    padding: 27px 0px;
    align-items: flex-start;

    @include media("<=desktop") {
      border-radius: 0px;
      box-shadow: none !important;
      padding: 0px;
    }
  }

  [data-user] {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 317px;

    @include media("<=desktop") {
      width: 100%;
    }

    [data-avatar] {
      margin-bottom: 13px;

      > div {
        background-position: center;
        background-size: cover;
        border: 1px solid black;
        border-radius: 50%;
        height: 148px;
        width: 148px;
      }
    }

    > [data-user-name] {
      color: $color-primary;
      font-size: 1.125rem;
      font-weight: $weight-bold;
      text-transform: uppercase;
      margin-bottom: 17px;
    }

    > [data-edit-profile] {
      color: $color-primary;
      font-size: 0.875rem;
      font-weight: $weight-semibold;
      margin-bottom: 23px;

      &:hover {
        text-decoration: underline;
      }
    }

    > [data-user-totals] {
      display: flex;
      flex-direction: row;

      & > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px;
        padding: 10px;
        min-width: 124px;
        transition: background-color 200ms ease-in-out;

        &:hover {
          background: rgba(10, 88, 115, 0.15);
        }

        &:first-of-type {
          margin-right: 8px;
        }

        span {
          font-size: 0.9375rem;
          margin-bottom: 12px;
          justify-content: center;
          text-align: center;
          color: $color-primary;
        }

        strong {
          font-size: 1.125rem;
          font-weight: $weight-bold;
          color: $color-primary;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  [data-history] {
    width: 526px;

    @include media(">=desktop") {
      border-right: solid 2px rgba(112, 112, 112, 0.2);
      border-left: solid 2px rgba(112, 112, 112, 0.2);
    }

    @include media("<=desktop") {
      width: 100%;
    }

    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;

    h4 {
      margin-bottom: 17px;
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, 186px);
      grid-template-rows: repeat(2, 113px) !important;
      gap: 26px;

      @include media("<=desktop") {
        gap: 10px;
        grid-template-rows: repeat(2, 120px) !important;
      }

      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        color: $color-primary;
        cursor: pointer;
        flex-direction: column;
        transition: background-color 200ms ease-in-out;
        width: auto;

        &:hover {
          background: rgba(10, 88, 115, 0.15);
        }

        &.active {
          background-color: #0a589b26;
        }

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0.5em 0px;

          span {
            font-size: 0.9375rem;
            margin: 0.5em 0;
            text-align: center;
            font-weight: $weight-normal;
            font-family: $font-family-monserrat;

            @include media("<=desktop") {
              font-size: 0.875rem;
            }
          }

          strong {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  [data-extra] {
    width: 403px;
    @include media("<=desktop") {
      width: 100%;
      padding-bottom: 15px;
    }

    h4 {
      margin-bottom: 56px;
    }

    display: flex;
    flex-direction: column;

    [data-knowlegde-areas-inner] {
      grid-template-columns: repeat(2, 1fr);

      @include media(">=desktop") {
        width: 280px;
      }

      [data-area-content] {
        height: 112px;

        @include media(">=desktop") {
          width: 127px;
        }

        > img {
          height: 49px;
        }

        > span {
          font-family: $font-family-monserrat;
          font-size: 12px;
          font-weight: $weight-semibold;
          zoom: 1;
        }
      }
    }
  }

  @include media("<=desktop") {
    [data-card] {
      flex-direction: column;
    }

    [data-user],
    [data-history] {
      margin-bottom: 3em;
    }

    [data-history] > div {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(2, 112px);
    }
  }
}
