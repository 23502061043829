@import "../../../ds/variables";
@import "../../../ds/media";

[data-ajuste-xpto] {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;

  a[data-link-primary] {
    display: none;
    margin: 0 !important;
  }

  h3 {
    margin: 0;
    color: $color-green-blue;
  }

  .ade-pagination {
    display: none;
  }

  a[data-link-primary] {
    display: block;
  }

  @include media("<=desktop") {
    margin-bottom: 1em;
  }
}