@import "../../ds/variables";
@import "../../ds/media";

[data-atendimento-page] {
  & > section {
    display: flex;
    flex-direction: row;
    padding: 60px $default-section-padding-side;
    justify-content: space-between;

    .charCount {
      font-size: 0.875rem;
    }

    @include media("<=desktop") {
      padding: 25px;
      flex-direction: column-reverse;
    }

    @include media("<=phone") {
      width: 100%;
      padding: 0px;
    }

    > aside {
      @include media("<=desktop") {
        padding: 50px 0px;
        width: 100%;
      }

      @include media("<=phone") {
        padding: 50px 25px;
      }

      > div {
        margin-bottom: 40px;

        ul {
          padding: 0px;
          > li {
            list-style: none;
          }
        }
      }

      [data-atendimento-telefone] {
        label {
          font-size: 0.875rem;
          font-weight: bold;
        }
        li {
          display: flex;
          align-items: center;
          color: #02b28e;
          font-weight: bold;
          font-size: 1.125rem;

          & > svg {
            margin-right: 8px;
          }

          & > a {
            font-weight: bold;
            color: #02b28e;
            border: none;

            @include media("<=phone") {
              cursor: pointer;
            }
          }
        }
      }

      [data-atendimento-horario] {
        li {
          font-size: 1rem;
        }
      }

      [data-atendimento-email] {
        li {
          a {
            color: $color-primary;
            font-size: 1rem;
            text-decoration: underline;
          }
        }
      }
    }

    h3 {
      color: $color-green-blue;
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 10px;
    }

    h4 {
      color: $color-primary;
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 6px;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 610px;
      padding: 50px;

      @include media("<=desktop") {
        width: 100%;
        padding: 25px;
        border-radius: 0px;
      }

      h4 {
        margin-bottom: 1rem;
      }

      .MuiFormLabel-root,
      .MuiFormLabel-root {
        font-family: $font-family-opensans;
      }

      .MuiFormControl-root {
        margin-bottom: 16px;
      }

      .MuiInputLabel-root {
        color: $color-default;
        font-size: 14px;
        font-weight: bold;
      }

      [data-buttons] {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        margin-top: 32px;

        button {
          padding: 0px;
          width: 162px;
          height: 35px;
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

[data-modal-atendimento-sucesso] {
  > h3 {
    color: $color-primary;
  }
}

[data-modal-atendimento-erro] {
  > h3 {
    color: $color-dark-red;
  }
}

[data-modal-atendimento-sucesso],
[data-modal-atendimento-erro] {
  padding: 60px 120px;

  @include media("<=phone") {
    padding: 200px 60px;
  }

  h3 {
    @include media("<=phone") {
      display: flex;

      justify-content: center;
    }
  }

  > [data-row] {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    &:not(:last-child) {
      margin: 2rem 0px;
    }

    > p {
      font-size: 1rem;
    }
  }
}
