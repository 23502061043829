@import "../../ds/media";

[data-oe-page] {
  .sem-acesso {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    text-transform: none;
  }
  [data-oe-episodios] {
    margin-top: 36px;
  }

  [data-oe-cardsection] {
    margin-top: 70px;

    @include media("<=desktop") {
      margin-top: 40px;
    }

    @include media("<=phone") {
      padding: 0px 32px!important;

      .card-section {
        &__title-bar {
          padding: 0;
          white-space: nowrap;
        }
        &__link {
          white-space: nowrap;
        }
      }

      .container {
        padding: 0;
      }
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }

    .card-section {
      margin-bottom: 0;
    }

    @include media(">=desktop") {
      .card-section {
        margin-bottom: 41px !important;
      }
    }
  }

  [data-banner][data-banner-fixo] {
    position: fixed;
    z-index: 999;
    bottom: 0;
    transform: translate(-50%, 0);

    left: 50%;
    width: 728px;
    height: auto;

    .swiper-container {
      padding-bottom: 0;
    }

    a {
      width: 100vw;
      height: auto;

      img {
        width: 728px;
      }
    }

    .banner-section {
      padding: 0;
      width: 728px;
    }

    @include media("<=phone") {
      width: 100vw;
      height: auto;

      .swiper-container {
        padding-bottom: 0;
      }

      a {
        width: 100vw;
        height: auto;

        img {
          width: 100vw;
          height: auto;
        }
      }

    }

    @include media("<=tablet") {
      width: 100vw;
      height: auto;

      .swiper-container {
        padding-bottom: 0;
      }

      a {
        width: 100vw;
        height: auto;

        img {
          width: 100vw;
          height: auto;
        }
      }
    }
  }

  @include media("<=phone") {
    .deslogado {
      bottom: 55px !important;
    }
  }
  @include media("<=tablet") {
    .deslogado {
      bottom: 55px !important;
    }
  }

  [data-banner][data-banner-rodape] {
    margin: 40px auto;
  }

  [data-oe-videos] {
    padding-top: 2px;
    padding-bottom: 2px;

    @include media("<=phone") {
      padding: 0px 32px!important;
    }

    [data-oe-videos-filter] {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1246px;
      margin: auto;

      .styles_selectField__P6zG1 {
        width: auto !important;
      }
    }
  }
}
