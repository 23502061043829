@import "../../../../../../ds/variables";
@import "../../../../../../ds/media";

[data-live-preview-mobile] {
  display: flex;
  width: 100%;
  flex-direction: column ;

  [data-image-preview-mobile] {
    width: 100%;
    height: 200px;
    background-size: cover;
    padding: 14px 32px 0 32px;
    flex-direction: column;
    justify-content: space-between;
  }

  [data-live-status-mobile] {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

[data-live-info-mobile] {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 10px 10px 0 0;
  margin-top: -15px;
  background-color: $color-white;
  z-index: 999;

  svg {
    fill: $color-default;
    margin-right: 10px;
    cursor: pointer;
  }

  @include media("<=desktop") {
    margin-right: 0px;
    width: 100%;
  }
    // background-image: linear-gradient(0deg,
    //     rgba(0, 0, 0, 0.92) 0%,
    //     rgba(0, 0, 0, 0.96) 10%,
    //     rgba(0, 0, 0, 0.96) 28%,
    //     rgba(0, 0, 0, 0.96) 80%);

  [data-all-video-audio] {
    @include media("<=desktop") {
      width: 100%;
      padding: 0px 25px;
    }
  }

  [data-all-video-audio] select {
    top: 225px;
    left: 120px;
    width: 240px;

    @include media("<=desktop") {
      width: 100%;
    }

    height: 32px;
    margin-bottom: 48px;
    padding: 5px;
    border-top: none;
    border-right: none;
    border-left: none;

    color: #fff;
    font-size: 14px;
  }

  h1 {
    color: $color-green-blue;
    font-size: 30px;
    font-weight: $weight-bold;
    width: 100%;
    margin-top: 18px;

      font-size: 16px;

    >span {
      margin-right: 20px;
    }
  }

  [data-tags] {
    margin-bottom: 8px;
  }

  [data-metadata] {
    display: flex;
    width: 100%;
    margin-bottom: 18px;

    @include media("<=desktop") {
      padding: 0px 25px;
    }

    [data-time-view] {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    [data-date-view] {
      display: flex;
      align-items: center;
      font-family: $font-family-opensans;

      >div {
        padding-bottom: 0px;
      }
    }
  }

  [data-tags] {
    @include media("<=desktop") {
      padding: 0px 25px;
    }
  }

  // [data-image-preview-mobile] {
  //   padding: 0px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   @include media("<=desktop") {
  //     height: 273px;
  //     width: 100%;
  //   }

  //   [data-image-gradient] {
  //     position: absolute;
  //     height: 100%;
  //     width: 100%;
  //     background: rgb(0, 0, 0);
  //     background: linear-gradient(0deg,
  //         rgba(0, 0, 0, 0.91) 0%,
  //         rgba(0, 0, 0, 0.014) 50%,
  //         rgba(0, 0, 0, 0.65) 80%);
  //   }

  //   [data-back-image] {
  //     height: 100%;
  //     width: 100%;
  //     background-size: cover;
  //     background-position: center top;
  //   }

  //   [data-play-button-mobile] {
  //     position: absolute;
  //     z-index: 120;
  //     width: 50px;

  //     &>img {
  //       width: 55px;
  //     }
  //   }
  // }



  [data-live-recommended-mobile] {
    width: 100%;
    margin-bottom: 24px;
    line-height: 1.3;

    @include media("<=desktop") {
      margin-bottom: 0px;
    }

    a {
      font-family: $font-family-monserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #111111;
      margin-top: 19px;
    }
  }

  [data-live-content-mobile] {
    width: 100%;
    height: auto;
    margin-right: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #9b9b9b #f4f4f4;
    scrollbar-width: thin;

    * {
      line-height: 1.5;
    }
    
    span {
      opacity: 0.2;
      border: 1px solid #707070;
      display: flex;
      margin-bottom: 12px;
      margin-top: 24px;
      width: 100%;
      display: flex;
    }

    a {
      font-family: $font-family-monserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      color: #707070;
    }

    p {
      font-family: $font-family-monserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #707070;
      margin-top: 12px;
    }

    @include media("<=desktop") {
      height: auto;
      width: 100%;
    }

    img {
      @include media("<=desktop") {
        width: 100%;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      background: #f4f4f4;
      border: solid 2px #e4e4e4;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0px;
      box-shadow: inset 0 0 6px #9b9b9b;
      -webkit-box-shadow: inset 0 0 6px #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
      margin: 2px;
      border-radius: 0px;
      position: absolute;
      background: #107b95;
      border: 2px solid #e4e4e4;
    }
  }

  .button {
    margin-top: 2rem;
    background-color: $color-green-blue;

    @include media("<=desktop") {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
  }
}

[data-preview-play] {
  position: absolute;
  left: 41%;
  top: 20%;

  .play-button {
    cursor: pointer;
    width: 40px !important;
    height: 40px  !important;
    display: block;
    text-align: center;
    margin: 30px auto;

    img {
      width: 60px;
      height: 60px;
    }

    &.hide-for-mobile {
      display: none !important;
    }
  }
}

[data-live-img-mobile] {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 200px;
}

[data-preview-em-breve-mobile] {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00498D;
  border-radius: 50px;
  width: 94px;
  height: 24px;
  margin: 0;

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    color: #FDFEFF;
  }
}

[data-close-mobile] {
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  background-color: #00498D;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
 
  .MuiSvgIcon-root {
    fill: #ffff;  
  }
}

[data-preview-aovivo-mobile] {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E62565;
  border-radius: 50px;
  width: 94px;
  padding: 8px;
  height: 24px;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    color: #FDFEFF;
  }
}

[data-preview-date-mobile] {

  border: 1px solid #DCE1E5;
  border-radius: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  border-radius: 50px;

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    color: #707070;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-right: 4px;
  }

  strong {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
  }
}

[data-preview-assista-agora-mobile] {
  background: #00498D;
  border-radius: 22.5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 35px;
  cursor: pointer;

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FDFEFF;
    width: 100%;
  }

  strong {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
  }
}

// [data-video-container] {
//   position: relative;
//   width: 100%;
//   min-height: 591px;
//   background-image: linear-gradient(
//     0deg,
//     rgba(0, 0, 0, 1) 27%,
//     rgba(201, 201, 201, 0) 51%,
//     rgba(0, 0, 0, 1) 77%
//   );
//   color: $color-white;

//   [data-video-container-image] {
//     background-image: linear-gradient(
//       0deg,
//       rgba(0, 0, 0, 1) 27%,
//       rgba(201, 201, 201, 0) 51%,
//       rgba(0, 0, 0, 1) 77%
//     );
//     img {
//       position: absolute;
//       width: 100%;
//       height: 80%;
//       mask-image: linear-gradient(
//         to bottom,
//         rgba(0, 0, 0, 0) 10%,
//         rgba(255, 255, 255, 1) 60%,
//         rgba(0, 0, 0, 0.65) 100%
//       );
//       mask-size: cover;
//       object-fit: cover;
//     }
//   }

//   [data-progress-bar] {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//   }

//   [data-video-container-wrapper] {
//     padding: 30px;
//   }

//   [data-button-back] {
//     padding: 15px 0;
//     margin-right: 15px;
//     display: none;
//   }

//   [data-preview-info] {
//     position: relative;

//     [data-preview-info-heading] {
//       padding: 0;

//       > div {
//         h2 {
//           color: $color-white;
//           text-transform: uppercase;
//           font-size: 14px !important;
//           font-weight: 700;
//           font-family: $font-family-monserrat;
//         }
//       }

//       [data-badges-wrapper] {
//         margin-bottom: 15px;
//         align-content: space-around;
//         justify-content: space-between;
//       }

//       [data-metadata] {
//         display: flex;
//         margin-bottom: 15px;
//         align-items: center;

//         [data-metadata-author] {
//           font-weight: bold;
//         }

//         img {
//           max-width: 23px;
//         }

//         #icon-calendar {
//           max-height: 18px !important;
//         }

//         > div {
//           margin-right: 15px;

//           &[data-metadata-author] {
//             text-transform: uppercase;
//             font-weight: 600;
//           }
//         }
//       }
//     }

//     [data-preview-description] {
//       margin: 15px 0;
//       p {
//         font-size: 16px !important;
//       }

//       h1,
//       h2,
//       h3,
//       h4 {
//         font-size: initial !important;
//         font-weight: normal;
//         margin-bottom: 15px;
//       }

//       select {
//         margin-bottom: 30px;
//       }
//     }


// }

//   @include media(">=desktop") {
//     [data-video-container-image] {
//       img {
//         width: 80%;
//         right: 0;
//         height: 100%;
//         mask-image: linear-gradient(
//           to right,
//           rgba(0, 0, 0, 0.1) 0%,
//           rgba(0, 0, 0, 1) 100%
//         );
//       }
//     }
//     background-image: linear-gradient(
//       90deg,
//       rgba(0, 0, 0, 1) 0%,
//       rgba(0, 0, 0, 1) 100%
//     );

//     [data-video-container-wrapper] {
//       display: flex;
//       width: 1246px;
//       margin: 0 auto;
//       padding: 0;
//     }

//     [data-button-back] {
//       display: block;
//       padding-top: 30px;
//     }

//     [data-preview-info] {
//       [data-preview-info-heading] {
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-start;
//         padding-top: 30px;

//         [data-metadata] {
//           [data-metadata-author],
//           [data-time-view],
//           [data-time-date] {
//             font-size: 14px !important;
//           }
//         }

//         > div {
//           width: 50%;
//           h2 {
//             font-size: 30px !important;
//             font-weight: 700;
//           }
//         }

//         [data-badges-wrapper] {
//           justify-content: start;
//           > div:not(:last-child) {
//             margin-right: 1rem;
//           }
//         }
//       }

//       [data-metadata] {
//         white-space: nowrap;
//       }

//       [data-preview-description] {
//         margin: 15px 0;
//         width: 40%;
//         max-height: 500px;
//         overflow-x: hidden;

//         select {
//           max-width: 80%;
//         }
//       }
//     }

//     .play-button {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       margin-left: -40px !important;
//     }
//   }
// }
