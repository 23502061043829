@import "../../../ds/variables";

[data-search-oes] {
  display: flex;
  flex-direction: row;
  font-family: $font-family-opensans;

  > div {
    display: flex;
    flex-direction: row;

    [data-possui-certificador-filter] {
      display: flex;
      align-items: center;
      font-size: 0.750rem;

      >input {
        margin-right: .5rem;
      }
    }
  }

  [data-select-field] {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
