@import "../../../../../ds/variables";
@import "../../../../../ds/media";

// Mixin Zoom Factor
// varia entre 1-10 onde 1 (default) é o menor fator e 10 o maior.
@mixin make-zoom-x($max) {
  @for $i from 2 through $max {
    .zoom-#{$i}x {
      *:not(h2) {
        font-size: 100% + ($i*10) !important;
      }
      h2 {
        font-size: 1rem + ($i) !important;
      }

      img {
        width: 60% +($i*10) !important;
      }
    }
  }
}

[data-infografico-oe-content] {
  display: flex;
  justify-content: space-between;

  @include media(">=desktop") {
    width: 1246px;
    margin: 0 auto;
  }

  @include media("<=desktop") {
    [data-oe-back-button] {
      display: none !important;
    }
  }

  [data-oe-back-button] {
    filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(150deg) saturate(600%) contrast(0.8);
    margin-right: 1em;
    padding-left: 0;
  }

  h1 {
    padding-top: 4rem;
    font-size: 1.875rem;
    color: $color-green-blue;
    text-transform: uppercase;
    font-weight: $weight-bold;
    margin-bottom: 2rem;
  }
  h2 {
    font-size: $font-med-larg !important;
    color: $color-default;
    font-family: $font-family-opensans;
    text-transform: none;
  }

  [data-tools] {
    &[data-tools*="navigation"] {
      padding-top: 4rem;

      > [button] {
        padding: 0;
        margin-left: -0.5em;
      }
    }
  }

  [data-text-container] {
    max-width: 1046px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    &[data-text-container*=infografico] {
      @include media("<=desktop") {
        padding: 0 25px;
      }
    }

    [data-infografico-image] {
      text-align: center;
      img {
        width: auto;
        height: auto;
        min-width: 60%;
        max-width: 100%;
      }
    }

    .zoom {
      max-width: 1046px !important;
    }

    // Generate Zoom X classes
    @include make-zoom-x(10)
  }

  [data-content-interactions] {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;

    @include media("<=desktop") {
      margin-right: .5rem;
    }

    > div {
      margin-bottom: 4rem;
      cursor: pointer;
    }
  }
}

[data-button-finish] {
  display: flex;
  flex-direction: row-reverse;
  padding: 2rem 0px;
  width: 100%;

  button {
    @include media("<=desktop") {
      margin-right: 25px;
    }
  }
}