@import "../../../ds/variables";
@import "../../../ds/media";
@import "../../../ds/mixins";

[data-modal-login] {
  display: flex;
  flex-direction: column;

  @include media(">=desktop") {
    width: 530px;
  }

  [data-modal-login-header] {
    display: flex;
    flex-direction: column;

    & > h4 {
      font-size: $font-med;
      color: $color-primary;
      font-weight: $weight-bold;
      text-transform: uppercase;
      margin: 33px 0px 30px 0px;
      text-align: center;
    }
  }

  [data-modal-login-form] {
    form {
      display: flex;
      flex-direction: column;

      [data-form-control] {
        display: flex;
        flex-direction: column;

        &:nth-child(1) {
          margin-bottom: 36px;
        }

        &:nth-child(2) {
          margin-bottom: 8px;
        }
      }

      [data-forgot-password] {
        display: flex;
        color: $color-primary;
        font-size: $font-sm;
        flex-direction: row-reverse;
        text-decoration: underline;
        font-family: $font-family-opensans;
        margin-bottom: 24px;
        cursor: pointer;
      }

      [data-stay-connected] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.3rem;
        font-size: 0.9em;

        label {
          margin-bottom: 4px;
          padding-top: 4px;
          display: flex;
          align-items: center;

          input {
            margin-right: 4px;
            cursor: pointer;
          }
        }

        span {
          font-size: 0.9em;
          margin: 0;
        }
      }

      [data-modal-login-error] {
        padding: 1rem;
        text-align: center;
      }
    }
  }
}
