@import "../../ds/variables";
@import "../../ds/media";

// $default-section-padding: 63px calc((100% - 1246px) / 2);// 63px 32px;

[data-my-profile] {
  // padding: $default-section-padding;

  @include media("<=desktop") {
    padding: 30px 0;
  }

  [data-title] {
    color: $color-primary;
    font-size: 18px !important;
    font-weight: $weight-bold;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
    font-family: $font-family-monserrat;
  }

  [data-no-content] {
    height: 300px;
    width: 100%;
  }

  [data-link-primary] {
    display: flex;
    text-decoration: none;
    font-size: $font-normal;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 42px !important;
  }

  [data-oe-cardsection] {
    padding-top: 40px;

    @include media("<=phone") {
      padding: 0px 35px;
      margin-top: 16px;
    }

    &:not(:last-child) {
      @include media("<=phone") {
        margin-bottom: 40px;
      }
    }
  }

  [data-box-notificacao-perfil] {
    border: 2px solid #bd331d;
    background-color: #bd331d33;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 26.5px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include media("<=desktop") {
      margin: 0px 55px 30px 55px;
    }

    p {
      font-size: 14px;
      color: #000;
      margin: 0;
      text-align: center;

      img {
        margin-top: -1px;
        margin-right: 5px;
      }

      a {
        text-decoration: underline;
        color: #0a589b;
      }
    }
  }
}
