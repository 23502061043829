@import '../../../ds/variables';

.tag {
    font-size: $font-xsm;
    border-radius: 30px;
    display: inline-block;
    color: $color-white;
    padding: 4px 11px;
    text-transform: uppercase;
    font-weight: $weight-bold;
    text-shadow: 0 0 1px $color-white;
    line-height: 17px;
    margin-right: 5px;
    font-family: $font-family-monserrat;
    white-space: nowrap;

    &-deep_dive {
        background-color: $color-orange;
    }

    &-pilula_de_conhecimento {
        background-color: $color-rose;
    }

    &-descomplicando_ciencia {
        background-color: $color-dark-purple;
    }

    &-einstein_practice {
        background-color: $color-primary;
    }

    &-fast_challenge {
        background-color: $color-purple;
    }

    &-teste_conhecimento {
        background-color: $color-purple;
    }

    &-opiniao_do_especialista {
        background-color: $color-green-seconday;
    }

    &-desafio_clinico {
        background-color: $color-light-blue;
    }

    &-reuniao_cientifica {
        background-color: $color-orange-dark;
    }

    &-conexao_de_ideias {
        background-color: $color-light-green;
    }

    &-cursos_online {
        background-color: $color-light-green;
    }

    &-pathways {
        background-color: $color-light-purple;
    }
}
