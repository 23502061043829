@import "../../../../../../ds/variables";
@import "../../../../../../ds/media";

[data-preferences-data] {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 395px;
  min-width: 1032px;
  position: relative;

  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    width: 100%;
    padding: 1rem 0px;

    p {
      margin: 1em 0px 0px 0px;
    }

    .wrapper-content {
      position: relative;

      .error-message {
        position: absolute;
        bottom: -1.45rem;
        left: 0;

        @include media("<=phone") {
          position: relative;
          bottom: -0.45rem;
        }
      }
    }
  }

  .option {
    padding: 12px 17px;
    border-radius: 30px;
    margin-right: 33px;
    margin-bottom: 20px;
    width: auto;

    &-default {
      border: solid 2px $color-default;
      color: $color-default;
      font-size: $font-normal;
      font-weight: $weight-normal;
      transition: all 200ms ease-in-out;

      &:hover {
        background-color: $button-option-hover;
      }

      &:focus {
        background-color: $button-option-hover;
      }
    }
  }

  [data-areas] {
    height: 200px;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    flex-wrap: wrap;
  }

  > button {
    margin-top: 2em;
  }

  [data-acceptSharePersonData] {
    display: flex;
    font-size: 11px;
    color: #5c6268;

    [data-flex] {
      align-items: center;
    }
  }

  @include media("<=desktop") {
    min-width: 100%;

    header {
      flex-direction: column;

      > .MuiFormControl-root {
        margin-top: 1em;
        width: 100%;
      }
    }

    [data-areas] {
      display: flex;
      justify-content: space-evenly;
    }

    .option {
      font-size: 0.6em !important;
      margin: 0.4em 0.4em;
    }
  }
}
