@import "../../../ds/media";

[data-dimmer] {
  animation: fadeIn 233ms ease;
  background-color: rgba(10, 10, 10, 0.7);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

[data-modal] {
  animation: fadeIn 233ms ease 233ms;
  animation-fill-mode: both;
  background-color: white;
  border-radius: 8px;
  height: fit-content;
  left: 50%;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: fit-content;
  z-index: 1001;

  @include media("<=desktop") {
    &.notFullScreenMobile {
      height: 100%;
      width: 100%;
      border-radius: 0px;
    }
  }

  &.fullscreen {
    height: 100vh;
    left: 0;
    overflow: auto;
    top: 0;
    transform: unset;
    width: 100vw;
  }

  &.halfscreen {
    height: 80vh;
    width: 70vw;
    display: flex;
    justify-content: center;

    margin: auto;
    overflow: none;
  }

  [data-modal-close] {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    cursor: pointer;
    z-index: 99999;

    width: 40px;
    height: 40px;

    background: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      padding: 10px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      transition: background 0.3s;
    }
  }
}
