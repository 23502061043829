@import "../../ds/variables";
@import "../../ds/media";

[data-area-patrocinador-page] {
  [data-header-section] {
    display: flex;
    min-height: auto;
    
    [data-header-section-sub-title] {
      display: flex;
      
      @include media(">=desktop") {
        margin-bottom: 2rem;
        h3 {
          font-family: $font-family-monserrat;
          font-style: $weight-normal;
          font-weight: $weight-semibold;
          font-size: $font-med1;
          line-height: 27px;
          color: $color-white;
        }
      }
    }

    [data-header-info] {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      align-self: flex-start;
      margin-top: 5px;

      @include media("<desktop") {
        width: 0.5rem;
        margin-top: 0px;
      }
    }
  }

  [data-container] {
    padding: 25px;

    @include media(">=desktop") {
      padding: 40px calc((100% - 1246px) / 2);
    }

    & > div {
      [data-title] {
        color: $color-green-blue;
        margin: 0px 0px 28px 0px;
      }

      [data-item-patrocinador] {
        display: flex;
        flex-direction: column;
        width: 100%;

        .card-section {
          margin-bottom: 0px;
        }

        .card-swiper {
          margin-top: 0px;
        }

        [data-swiper-adapter] {
          overflow: hidden;
          width: auto;
          height: 290px;
          margin-left: 0px;
          padding: 0px;

          @include media(">=desktop") {
            padding: 36px 0 0;
            margin-left: -30px;
            position: absolute;
            height: 365px;
          }

          .swiper-button-prev,
          .swiper-button-next {
            background-color: rgba(255, 255, 255, 0.7);
            font-size: 10px;
            width: 36px;
            height: 36px;
            border-radius: 25px;
            color: $color-primary;

            &:after {
              font-size: 13.5px;
              font-weight: bold;
            }

            @include media("<=phone") {
              display: none;
            }
          }

          .swiper-button-prev {
            left: -18px;
          }
          .swiper-button-next {
            right: -18px;
          }

          .swiper-container {
            overflow: visible;
            height: 100%;
            @include media(">=desktop") {
              width: 400px;
              height: 300px;
              margin: 0px 0px 0px 29px;
            }
          }

          .swiper-pagination.swiper-pagination-bullets {
            width: 100%;
            bottom: -23px;

            @include media(">=desktop") {
              bottom: unset;
              top: -54px;
              left: 42%;
              width: auto;
            }
          }

          .swiper-pagination-bullets {
            bottom: 10px;
          }

          .swiper-pagination-bullet.swiper-pagination-bullet-active {
            opacity: 1;
            background-color: #fff;
            width: 10px;
            height: 10px;
          }

          .swiper-pagination-bullet {
            opacity: 0.5;
            margin: 0 10px !important;
            width: 8px;
            height: 8px;
            background-color: rgba(255, 255, 255, 0.8);
          }

          @include media("<=phone") {
            .destak-item {
              height: 150px;

              &__title {
                font-size: $font-sm !important;
                margin-bottom: 0.5rem;
                line-height: 1.3;
              }
              &__deep-dive {
                img,
                span {
                  display: none;
                }
              }

              &__description {
                display: none;
              }

              &__footer {
                font-size: $font-xsm;
                img {
                  max-width: 14px;
                  max-height: 10px;
                }
                > div:first-child > div:first-child {
                  border-width: 1px;
                }
              }
            }
          }
        }

        @include media("<=phone") {
          .card-section {
            &__link {
              position: absolute;
              right: 25px;
              margin-top: -25px;
            }
          }
        }
      }
    }
  }
}
