@import "../../ds/variables";
@import "../../ds/media";

[data-ensino-einstein-inner] {
  [data-media-container] {
    padding-top: 0px;
    padding-bottom: 0px;
    [data-grupo-medias] {
      margin-top: 41px;
      margin-bottom: 58px;
      > h2 {
        font-size: 1.75rem !important;
        font-family: $font-family-monserrat;
        text-transform: uppercase;
        color: $color-green-blue !important;
        margin-bottom: 2rem;

        @include media("<=desktop") {
          font-size: 1.25rem !important;
        }
      }

      [data-course-link] {
        cursor: pointer;
      }

      [data-card-content] {
        background-position: center top !important;
      }

      [data-course-soon] {
        position: absolute;
        width: 299.5px;
        height: 100%;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;

        @include media("<=desktop") {
          width: 100%;
        }

        [data-course-soon-info] {
          position: absolute;
          z-index: 980;
          padding: 0px 25px;
          color: $color-white;
          font-size: 1.25em !important;
          text-transform: uppercase;
          font-weight: $weight-bold;
          border-top-left-radius: 8px;

          h4 {
            font-size: 1.25rem !important;
            font-weight: $weight-semibold !important;
          }
        }
      }
    }
  }
}
