@import "../../../ds/variables";
.home-videos-higher {
  &-item {
    background-size: cover !important;
    // width: 100%;
    height: 100% !important;
    height: 300px;
    border-radius: 0.625rem;
    .imagem {
      border-radius: 8px;
      // width: 100%;
      height: 91%;
      // min-height: 270px;
      overflow: hidden;
      position: absolute;
      background-clip: padding-box;
      background-position: center;
      background-size: cover;
    }
    [data-download] {
      align-items: center;
      animation: fadeIn 433ms ease;
      background-color: rgba(16, 123, 149, 0.8);
      border-radius: 10px;
      color: white;
      cursor: pointer;
      display: none;
      flex-direction: column;
      font-family: "Open Sans";
      font-weight: 600;
      height: 100%;
      justify-content: center;
      position: absolute;
      text-align: center;
      width: 100%;
      z-index: 1001;
      .MuiSvgIcon-fontSizeLarge {
        font-size: 5em;
      }
    }
    &:hover [data-download] {
      display: flex;
    }
    &__card {
      position: absolute;
      top: 20px;
      color: $color-white;
      width: 100%;
      // padding: 0px 15px;
      height: 100%;
    }
    &__title {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: $font-med2;
      font-weight: $weight-bold;
      // text-transform: uppercase;
    }
    &__description {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: $font-normal;
    }
    &__deep-dive {
      &__public-date {
        font-size: $font-normal;
        font-weight: $weight-normal;
      }
    }
  }
}
[data-tag-certificado] {
  background-color: #ddd;
  color: #333;
  display: inline-flex;
  font-family: "Open Sans";
  font-size: 14px;
  padding: 0.2em 0.4em;
}