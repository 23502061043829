@import "../../../../ds/variables";
@import "../../../../ds/media";

.form-base{
  &__title{
    @include media('<=desktop') {
      margin-top: 2rem;
    }
    @include media('>desktop') {
      margin-bottom: 2rem;
    }
  }
}


.forgot-password {
  &-title {
    padding: 5% 0%;
    font-size: 0.985rem;
  }
  &-logo {
    padding: 5% 0%;
  }
}
.form-preference {
  width: 100%;
  margin-top: 11px;
  line-height: 1.3;

  .flex {
    width: 100%;
    justify-content: flex-start;
  }

  &__description {
    display: flex;
    font-size: $font-normal;
    font-weight: $weight-normal;
    color: $color-default;
  }

  &__options {
    max-height: 186px;
    margin-top: 40px;
    overflow-y: scroll;
    margin-bottom: 32px;

    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #9b9b9b #f4f4f4;
    scrollbar-width: thin;

    .option {
      padding: 10px 14px;
      border-radius: 30px;
      margin-right: 33px;
      margin-bottom: 20px;
      white-space: nowrap;
      width: auto !important;

      &-default {
        border: solid 2px $color-default;
        color: $color-default;
        font-size: $font-normal;
        font-weight: $weight-normal;
        transition: all 200ms ease-in-out;

        &:hover {
          background-color: $button-option-hover;
        }

        &:focus {
          background-color: $button-option-hover;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      background: #f4f4f4;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f4f4f4;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: #107b95;
      border-radius: 0px;
      margin: 2px;
    }
  }

  [data-accpects] {

    @include media("<=desktop") {
      flex-direction: column;
      width: 100%;
    }

    .accept-term {
      font-family: $font-family-opensans;
      font-size: 0.6875rem;
      color: $color-default;
      cursor: pointer;
      width: 25%;
  
      @include media('<=desktop') {
        width: 100%;
        margin-top: 2em;
      }
      
      &-click {
        display: inline-flex;
        color: $color-primary;
        font-weight: bold;
        text-decoration: underline;
        padding-left: 0.2em;
        padding-right: 0.2em;
      }
  
      & > input {
        margin-right: 0.625rem;
      }
  
      &__description {
        line-height: 1.5;
      }
  
      [data-error] {
        font-size: 12px;
      }
    }
  
    .accept-term:first-child{
      @include media('<=desktop') {
        padding-right: 0.5em;
        
      }
      @include media('>desktop') {
        margin-right: 2em;
      }
      
    }
  }

  [data-form-footer] {
    width: 100%;
    margin-top: 2em;
    display: flex;
    justify-content: space-between;

    @include media('<=desktop') {
      justify-content: space-around;
      margin-top: 2rem;
    }

    button {
      @include media('<=desktop') {
        width: 130px;
      }
    }
  }
}
