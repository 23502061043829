@import "../../../../ds/variables";

.user {
  &__header {
    margin-bottom: 16px;
  }

  &__title {
    font-size: $font-med;
    font-weight: $weight-bold;
    color: $color-primary;
    text-transform: uppercase;
    margin-bottom: 0px !important;
  }

  &__steps {
    padding-top: 10px;
    padding-left: 30px;
    height: 70px;

    &__line {
      position: absolute;
      margin-top: 5px;
      width: 65vw;
      display: flex;
      justify-content: space-between;
    }

    .step {
      display: flex;
      justify-content: center;
      background: transparent;
      .activate {
        height: 23px;
        width: 23px;
        background: $color-white;
        display: flex;
        justify-content: center;
        z-index: 100;
        img {
          width: 13px;
          height: 13px;
        }
      }

      position: relative;

      &.active:before {
        border: 1px solid #0a589b;
        content: "";
        width: 32vw;
        height: 0;
        left: 0;
        top: 5px;
        position: absolute;
        z-index: 10;
      }

      .unactivate {
        height: 23px;
        width: 23px;
        background: $color-white;
        display: flex;
        justify-content: center;
        z-index: 100;
        img {
          width: 13px;
          height: 13px;
        }
      }

      .label {
        position: absolute;
        text-align: center;
        font-size: $font-sm;
        width: 90px;
        font-weight: $weight-semibold;
        z-index: 100;
        margin-top: 20px;
        color: $color-gray-light;

        &-activate {
          color: $color-primary;
        }
      }
    }

    .line {
      height: 2px;
      background: $color-gray-light;
      width: 100%;
      position: absolute;
      margin-top: 5px;
    }
  }
}
