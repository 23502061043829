@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-modal-term] {
  align-items: center;
  width: 800px;
  height: 550px;
  padding: 40px;  

  @include media("<=desktop") {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 80px 40px 40px;
  }

  [data-modal-term-content] {
    max-height: 70%;
    line-height: 1.4;
    padding-right: 1rem;
    overflow-y: auto;
    margin-bottom: 2em;
    

    @include media("<=desktop") {
      max-height: 50vh;
      max-width: 80vh;
    }

    overflow-x: hidden;
    scrollbar-color: #9b9b9b #f4f4f4;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
      background: #f4f4f4;
      border-left: solid 1px #cacaca;
      border-right: solid 1px #cacaca;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0px;
      box-shadow: inset 0 0 6px #9b9b9b;
      -webkit-box-shadow: inset 0 0 6px #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
      margin: 2px;
      border-radius: 0px;
      position: absolute;
      background: #107b95;
      border-left: 1px solid #cacaca;
      border-right: 1px solid #cacaca;
    }
  }

  h3 {
    color: $color-primary;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  [data-footer] {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;

    @include media('<=desktop') {
      justify-content: space-around;
      margin-top: 2rem;
    }

    button {
      &:first-child {
        margin-right: 1rem;
      }
      @include media('<=desktop') {
        width: 130px;
      }
    }
  }
}