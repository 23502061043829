@import "../../../ds/variables";
@import "../../../ds/media";

$gray-color: #ededed;
$green-color: #117b95;

.ade-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0;

  @include media("<=phone") {
    justify-content: center;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
  }

  ul li {
    list-style: none;
    padding: 0;
    margin: 0.35rem;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 100%;
    background-color: $gray-color;

    &.active {
      background-color: $green-color !important;
      button {
        color: $gray-color !important;
      }
    }

    &:first-child {
      background-color: transparent;
    }

    &:last-child {
      margin-right: 0;
      background-color: transparent;
    }

    button {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0.3rem 0.15rem;
      font-size: 85%;
      font-weight: 500;
      line-height: 1.5;

      background-color: transparent;
      color: $green-color;

      span {
        vertical-align: top;
        line-height: 1.1;
      }

      &:disabled {
        color: $color-gray-light;
        cursor: default;
      }
    }
  }
}
