@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-widget-pathways] {
  display: flex;
  flex-direction: column;
  @include media("<desktop") {
    // margin-top: 2rem;
  }
  width: 100%;

  @include media(">=phone") {
    // width: 400px;
    width: auto;
  }

  h3 {
    margin: 0px 0px 16px 0px;
    font-family: $font-family-monserrat;
    font-style: $weight-normal;
    font-weight: $weight-semibold;
    font-size: $font-med2;
    line-height: 20px;
  }

  h5 {
    margin: 0px 0px 16px 0px;
    font-family: $font-family-monserrat;
    font-style: $weight-normal;
    font-weight: $weight-semibold;
    font-size: $font-med2;
    line-height: 20px;
  }
}

[data-card-pathways] {
  box-shadow: 0px 3px 6px #00000029;
  width: auto;
  height: 83px;
  background-color: #ffffff;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  cursor: pointer;

  svg {
    margin-right: 12px;
  }

  > div:nth-child(2) {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #9a0b83;
    width: 100%;
    h5 {
      margin: 0;
    }
  }

  p {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #707070;
    margin: 0;
  }
}

[data-rotulo-pathways] {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 10px;

  width: 89px;
  height: 24px;

  background: #9a0b83;
  border-radius: 20px;

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #fdfeff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
