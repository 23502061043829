@import "../../../../ds/variables";
@import "../../../../ds/media";

[data-professional-information] {
  .form-base {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__title {
      @include media('<=desktop') {
        margin-top: 2rem;
      }
      @include media('>desktop') {
        margin-bottom: 2rem;
      }
    }

    form {
      display: flex;
      width: 100%;
      flex-direction: column;

      [data-form-wrapper] {
        display: flex;
        flex-direction: row;
        width: 100%;
        @include media('<=desktop') {
          display: inline !important;
        }
        [data-col] {
          display: flex;
          flex-direction: column;
          width: 50%;
          @include media('<=desktop') {
            width: 100% !important;
          }
          .MuiFormControl-root {
            width: 100%;
            margin-bottom: 2rem;
            position: relative;

            .MuiFormLabel-root {
              font-family: $font-family-monserrat;
              font-weight: bold;
              font-size: 13px;
            }

            .MuiFormHelperText-root {
              position: absolute;
              bottom: -17px;
            }
          }

          &:first-child {
            padding-right: 0.5rem;
          }

          &:last-child {
            padding-left: 0.5rem;
          }
        }

        [data-col]:last-child {
          @include media('<=desktop') {
            padding-left: 0 !important;
          }
        }

      }

      [data-form-footer] {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        @include media('<=desktop') {
          justify-content: space-around;
          margin-top: 2rem;
        }

        button {
          @include media('<=desktop') {
            width: 130px;
          }
        }

        [data-not-register] {
          font-size: 14px;
          margin: 0;
        
          [data-mailing] {
            color: $color-default !important;
            text-decoration: underline;
            font-weight: bold;
        
            &:active {
              color: $color-default !important;
            }
          }
        }
      }
    }
  }
}