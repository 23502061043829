@import "../../../../ds/media";
@import "../../../../ds/variables";

[data-modal-quase-la] {
  padding: 1em;

  width: 100%;
  height: 100%;

  @include media(">=phone") {
    width: 530px;
    height: 269px;
    padding: 1em;    
  }

  h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $color-primary;
    margin: 100px 30px 20px 30px;
    text-align: center;
    line-height: 1.3;
    font-family: $font-family-monserrat;

    @include media(">=phone") {
      margin: 30px 0px 20px 0px;
    }
  }

  [data-row] {
    display: flex;
    flex-direction: column;

    @include media(">=phone") {
      flex-direction: row;
    }

    p {
      font-size: 0.9375rem;
      
      text-align: center;
      line-height: 1.3;
      margin: 0 10px 32px 10px;

      @include media(">=phone") {
        margin: 0 61px 16px 61px;
      }
    }
  }

  [data-row][data-flex-row-arround] {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include media(">=phone") {
      padding: 0 75px;
      justify-content: space-between;
    }

    button {
      margin-bottom: 16px;

      @include media(">=phone") {
        width: 162px;
      }
    }
  }

}
