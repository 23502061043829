[data-favoritar] {
  img {
    cursor: pointer;
  }
}
.like-container {
  margin-right: 0%;
  img {
    height: 14px;
  }
}
