@import "../../../ds/variables";
@import "../../../ds/media";

[data-loader-content] {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  background: #fff;
  height: 300px;
  color: $color-default;
  opacity: 1;

  @include media("<=phone") {
    height: 100px;
  }

  &[data-loader-content*="false"] {
    opacity: 0;
    transition: opacity 300ms linear;
  }

  h4 {
    margin: 0;
    padding: 0;
  }
}
