@import "../../../ds/media";

[data-curtir] {
  img {
    cursor: pointer;
  }
}
.like-container {
  margin-right: 0px;
  img {
    height: 25px;
    @include media("<=phone") {
      height: 21px;
    }
  }
}
