@import "../../ds/variables";
@import "../../ds/media";

[data-area-conhecimento-page] {
  [data-banner] {
    margin: 0 auto;
  }
  
  [data-banner][data-banner-cabecalho] {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  [data-banner][data-banner-rodape] {
    margin: 40px auto;
    width: 800px;
    height: 150px;

    @include media("<=phone") {
      width: 250px;
      height: 150px;
    }
  }
}

[data-interest-area-container] {
  margin: 50px 0;

  @include media("<=phone") {
    > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media("<=desktop") {
    margin-bottom: 43px;
  }

  h3 {
    color: $color-white;
  }

  [data-interest-areas-inner] {
    width: 100%;
    margin: 0;
    padding: 0px 36px;
    

    @include media(">=desktop") {
      width: 1246px;
      margin: 0 auto;
      padding: 0px 0px 25px 0px;
    }
  }
}
