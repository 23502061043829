@import "../../ds/variables";
@import "../../ds/media";

$area-color: (
  "pathways": linear-gradient(137.1deg, #673FB4 6.6%, #9b2fae4a 98.07%),
  "default": linear-gradient(105deg, #107b95 0%, #1bb28e 100%),
);

.oe-search-result-filters {
  background-size: cover;
  height: 100%;
  width: 100%;
  color: $color-white;
  position: relative;

  display: flex;
  padding: 0px;

  @include media("<=desktop") {
    padding: 25px;
  }

  @each $name, $value in $area-color {
    &.#{$name} {
      h2 {
        padding-bottom: 0.2em !important;
      }
    }
  }
}

[data-filter-area-conhecimento] {
  label{
    font-size: .75rem !important;
    text-transform: uppercase;
    font-weight: 600;
  }
}

[data-empty-sub-metodos] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}