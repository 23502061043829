@import "../../../../../../ds/variables";
@import "../../../../../../ds/media";

[data-destaques] {
  [data-hora-live] {
    align-items: center;
    display: flex;
    font-family: "Open Sans";
    justify-content: center;

    & > .date-icon {
      margin-left: -6px;
      height: 30px;
      margin-top: 2px;
    }

    & > .date-icon-calendar {
      height: 16px;
      margin-right: 5px;
    }

    > div {
      margin-left: 0 !important;
      align-items: center;
      display: flex;

      > span {
        display: inline-block !important;
        margin-left: 0 !important;
        font-family: $font-family-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 0.69rem;
        line-height: 13px;
        white-space: nowrap;

        &.space {
          padding: 0 0.2em;
        }
      }
    }

    &[data-animate] {
      > div > span {
        animation: fadeInDown 433ms ease;
        animation-fill-mode: both;

        @for $i from 1 to 10 {
          $delay: $i * 133;

          &:nth-child(#{$i}) {
            animation-delay: #{$delay}ms;
          }
        }
      }
    }
  }
  .destak-item {
    background-size: cover !important;
    width: 100%;
    height: 280px;

    @include media(">=desktop") {
      height: 363px;
    }

    border-radius: 0.625rem;
    position: absolute;

    .imagem {
      border-radius: 8px;
      width: 100%;
      @include media("<=phone") {
        height: 91%;
      }
      height: 93%;
      // min-height: 270px;
      overflow: hidden;
      position: absolute;
      background-clip: padding-box;
      background-position: center;
      background-size: cover;
    }

    .data-live {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__card {
      color: $color-white;
      padding: 24px 0px 0px 24px;
      height: 100%;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 12px;
      font-size: 16px !important;
      font-weight: 600;
      text-shadow: 0 0 1px white;
    }

    &__description {
      margin-top: 0;
      width: 70%;
      margin-bottom: 20px;
      font-size: $font-normal;
      margin-top: 12px;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
    }

    &__deep-dive {
      align-items: center;
      display: flex;
      margin-bottom: 15px;
      img {
        margin-bottom: -5px;
      }

      .tag {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
    .tag {
      white-space: nowrap;
      margin-right: 10px;
    }
  }
}

.dark-slide {
  height: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
}

[data-preview-em-breve-desktop] {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00498d;
  border-radius: 50px;
  width: 94px;
  height: 24px;
  margin-bottom: 12px;

  span {
    font-family: $font-family-monserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    color: #fdfeff;
  }
}
