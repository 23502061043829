@import "../../../../ds/variables";
@import "../../../../ds/grid";
@import "../../../../ds/media";

[data-quiz] {
  border-radius: 12px;
  display: flex;
  justify-content: space-between;

  flex-direction: column;
  height: auto;
  width: 100%;

  @include media("<=phone") {
    display: flex;
  }

  @include media(">=desktop") {
    width: 100%;
    height: 253px;
    .home-videos-higher-item {
      //  box-shadow: 0px 3px 6px #00000029;
      max-height: 300px;
      min-height: 255px;
    }
  }

  h3 {
    margin: 0px 0px 16px 0px;
    font-family: $font-family-monserrat;
    font-style: $weight-normal;
    font-weight: $weight-semibold;
    font-size: $font-med2;
    line-height: 20px;
  }

  [data-card] {
    position: relative;
    padding: 0px !important;
    @include media(">=desktop") {
      height: 300px;
      height: 100%;
    }

    header {
      padding: 24px 24px 6px 24px;
      h4 {
        width: 100%;
        font-weight: bold;
        color: $color-green-blue;
      }

      .tag {
        margin-bottom: 0px;
        margin-right: 10px;
      }

      [data-date-view] {
        img {
          margin-right: 8px;
        }
      }
    }

    [data-card-content] {
      width: 100%;
      display: flex;
      align-items: stretch;
      overflow: initial !important;
      padding: 0px 24px;
      height: 100%;

      @include media("<=desktop") {
        margin-bottom: 70px;
      }

      [data-card-content-info] {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        p {
          padding-top: 0;
          padding-bottom: 1rem;
          margin: 0px;

          font-family: $font-family-monserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 15px;
          color: #707070;
        }

        & > div {
          display: flex;
          width: 100%;
          justify-content: flex-start;

          .button {
            text-align: center;
            display: block;
            @include media(">=desktop") {
              width: 100%;
            }
            background: #107b95;
            border: solid 2px #107b95;
          }
        }
      }

      [data-card-content-image] {
        width: 238px;
        height: 144px;
        text-align: center;
        img {
          width: auto;
          height: 100%;
        }

        @include media("<=desktop") {
          display: none;
        }
      }
    }
  }

  @include media("<=desktop") {
    [data-card] {
      header {
        > div:first-of-type {
          align-items: center;
          display: flex;
        }
      }

      height: auto;

      h4 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }

      [data-card-content] {
        div:first-child {
          align-items: stretch;
          padding-right: 0;
        }
        img {
          display: none;
        }
      }
    }
  }

  [data-card] {
    footer {
      position: relative !important;
    }
  }

  [data-footer-oe-quizz-patrocinada] {
    border-radius: 0 0 0.625rem 0.625rem;
    display: flex;
    align-items: center;
    bottom: 0;
    color: white;
    font-size: 0.5rem;
    font-weight: bolder;
    padding-left: 1rem;
    background-color: #6d6e70;
    width: 100%;
    height: 1.5rem;
  }
}

[data-quiz] {
  [data-card-content] {
    width: 100%;
    .button-primary {
      @include media("<=desktop") {
        display: block;
        width: 100%;
      }
    }
  }
}
