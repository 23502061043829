@import "../../../../../../ds/media";
@import "../../../../../../ds/variables";

[data-personal-data],
[data-professional-data] {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .MuiFormLabel-root {
    font-weight: bold !important;
    font-size: 1rem;
    color: $color-default;
    font-family: $font-family-monserrat;

    &.MuiFormLabel-root.Mui-error {
      color: #f44336;
    }
  }

  .MuiInputBase-root {
    &.MuiInput-underline.Mui-error {
      &:after {
        border-color: #f44336;
      }
    }
  }

  [data-avatar] {
    background-size: cover;
    background-position: center;
    border: 1px solid rgba(17, 17, 17, 0.3);
    border-radius: 1000px;
    display: flex;
    height: 148px;
    justify-content: center;
    margin-bottom: 2em;
    width: 148px;

    button {
      background-color: #0a589b;
      border: 3px solid white;
      color: white;
      height: 34px;
      margin-top: 5.4em;
      width: 34px;
    }
  }

  > form > fieldset {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 0;

    > .MuiFormControl-root {
      margin: 0.5em;
    }
  }

  > form > button {
    margin-left: 50%;
    margin-top: 3em;
    transform: translateX(-50%);
    margin-top: 3em;
  }
}

[data-preferences-data],
[data-personal-data],
[data-professional-data] {
  [data-row-center] {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    margin-bottom: 73px;

    button {
      margin-right: 1rem;
    }

    @include media("<=phone") {
      a {
        display: none;
      }
    }
  }
}

.wrapper-phone-number {
  position: relative;
  margin: 0.5em;

  .phone-number {
    width: 100%;
  }

  .field-link {
    position: absolute;
    bottom: -1.45rem;
    right: 0;
    display: flex;
    align-self: flex-end;
    font-size: 12px !important;
    font-weight: normal;
    color: #0a589b;
    text-decoration: underline;
    cursor: pointer;
    // -webkit-appearance: button;
    // box-sizing: border-box;
    // -webkit-font-smoothing: antialiased;
    padding-bottom: 0 !important;
    padding-top: 1rem;
  }
}
